import React from 'react'

export default function Loading() {
  return (
    <div className='flex h-screen w-screen justify-center items-center '>
    <img
         className="h-32 w-32"
         src={require("../assets/png/logoLoading.png")}
         alt=""
       />
    <div className='bg-orange-600 animate-ping h-7 w-7 rounded-full absolute mt-14 ml-16'  />  
</div>
  )
}
