import React, { Fragment } from 'react'
import { Dialog, Transition } from "@headlessui/react";
import {
  faTimes,
  faUserCircle,
  faSignOut,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '../../store/hooks';

interface LoginAndRegisterPanelInterface {
  userOpen: boolean;
  setUserOpen: React.Dispatch<React.SetStateAction<boolean>>;
  availabilityAlert: boolean;
  setActualView: React.Dispatch<React.SetStateAction<string>>
  setShowModalLogIn: React.Dispatch<React.SetStateAction<boolean>>
  setShowModalRegister: React.Dispatch<React.SetStateAction<boolean>>
  setOpenLogOutModal: React.Dispatch<React.SetStateAction<boolean>>
}

const LoginAndRegisterPanel = ({
  userOpen,
  setUserOpen,
  availabilityAlert,
  setActualView,
  setShowModalLogIn,
  setShowModalRegister,
  setOpenLogOutModal
}: LoginAndRegisterPanelInterface) => {

  const { user, business } = useAppSelector(state => state.session)


  const when_shop_create_preorder =
    business?.configurationsKey.filter(
      (config) => config.key === "when_shop_create_preorder"
    )[0].value === "true";

  return (
    <Transition.Root show={userOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={setUserOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Usuario
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => setUserOpen(false)}
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>

                      {!user ? (
                        <div className="w-full mt-20 flex flex-col justify-center">
                          <h2 className="font-semibold text-3xl text-center">
                            Bienvenid@
                          </h2>

                          <div className="w-full flex justify-center">
                            <FontAwesomeIcon
                              icon={faUserCircle}
                              className="h-32 w-32 mt-10 flex-shrink-0 text-gray-300"
                              aria-hidden="true"
                            />
                          </div>

                          <div className="mt-6">
                            <div
                              onClick={() => {
                                if (
                                  !availabilityAlert ||
                                  when_shop_create_preorder
                                ) {
                                  setShowModalLogIn(true);
                                }
                              }}
                              className={
                                "hover:bg-orange-600 bg-orange-500 cursor-pointer flex items-center justify-center rounded-md border border-transparent  px-6 py-3 text-base font-medium text-white shadow-sm"
                              }
                            >
                              Iniciar sesión
                            </div>
                          </div>

                          {/* <p className="text-center px-6 py-3 mt-3">ó</p> */}

                          <div className="mt-6">
                            <div
                              onClick={() => {
                                if (
                                  !availabilityAlert ||
                                  when_shop_create_preorder
                                ) {
                                  setShowModalRegister(true);
                                }
                              }}
                              className={
                                "hover:bg-orange-600 bg-orange-500 cursor-pointer flex items-center justify-center rounded-md border border-transparent  px-6 py-3 text-base font-medium text-white shadow-sm"
                              }
                            >
                              Registrarte
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="w-full mt-10 flex flex-col justify-center">
                          <div className="w-full flex justify-start">
                            {user.avatar ? (
                              <img
                                className="h-10 w-10 mx-2 flex-shrink-0 text-gray-300 rounded-full"
                                src={user.avatar.src}
                                alt=""
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faUserCircle}
                                className="h-10 w-10 mx-2 flex-shrink-0 text-gray-300"
                                aria-hidden="true"
                              />
                            )}

                            <p className="text-lg">
                              {user.displayName
                                ? user.displayName
                                : user.email}
                            </p>
                          </div>

                          <div className="w-full mt-2 flex justify-between">
                            <button
                              className="mx-4 my-4 text-red-500"
                              onClick={() => {
                                setOpenLogOutModal(true);
                              }}
                            >
                              Cerrar sesión
                              <FontAwesomeIcon
                                icon={faSignOut}
                                className="ml-1 text-red-500"
                                size="sm"
                                aria-hidden="true"
                              />
                            </button>

                            <button
                              className="mx-4 my-4 text-blue-500"
                              onClick={() => {
                                setUserOpen(false);
                                setActualView("profile-info");
                              }}
                            >
                              Ver información
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                className="ml-1 text-blue-500"
                                size="sm"
                                aria-hidden="true"
                              />
                            </button>
                          </div>

                          <h2 className="text-3xl font-semibold mt-20 capitalize">
                            última <span className="lowercase">compra</span>
                          </h2>
                          <p className="text-gray-400">
                            Compruebe el estado de los pedidos recientes,
                            gestione las devoluciones y descubra productos
                            similares.
                          </p>

                          <button
                            className="mx-4 my-4 text-blue-500"
                            onClick={() => {
                              setUserOpen(false);
                              setActualView("orders-history");
                            }}
                          >
                            Ver historial de órdenes
                            <FontAwesomeIcon
                              icon={faArrowRight}
                              className="ml-1 text-blue-500"
                              size="sm"
                              aria-hidden="true"
                            />
                          </button>

                          {/* <div className="mt-10 space-y-16 ">
                            {orders !== undefined && orders?.length >= 1 ? (
                              <>
                                {orders
                                  ?.map((order) => (
                                    <section
                                      className="ring-1 ring-slate-200 rounded-md py-4 px-4"
                                      key={order.operationNumber}
                                      aria-labelledby={`${order.operationNumber}-heading`}
                                    >
                                      <dl className="flex-auto space-y-4 divide-y divide-gray-200 text-sm text-gray-600 md:grid md:grid-cols-3 md:gap-x-6 md:space-y-0 md:divide-y-0 lg:flex-none lg:gap-x-8">
                                        <div className="flex justify-between md:block">
                                          <dt className="font-medium text-gray-900">
                                            Orden
                                          </dt>
                                          <dd className="md:mt-1">
                                            {order.operationNumber}
                                          </dd>
                                        </div>
                                        {order?.shippingPrice?.amount > 0 && (
                                          <div className="flex justify-between pt-4 font-medium text-gray-900 md:block md:pt-0">
                                            <dt className=" whitespace-nowrap">
                                              Envío
                                            </dt>
                                            <dd className="md:mt-1 whitespace-nowrap">
                                              {printPriceWithCommasAndPeriods(
                                                order?.shippingPrice?.amount
                                              ) +
                                                " " +
                                                order?.shippingPrice
                                                  ?.codeCurrency}
                                            </dd>
                                          </div>
                                        )}
                                        <div className="flex justify-between pt-4 font-medium text-gray-900 md:block md:pt-0">
                                          <dt>Total</dt>
                                          <dd className="md:mt-1">
                                            {printPriceWithCommasAndPeriods(
                                              order?.totalToPay[0]?.amount
                                            ) +
                                              " " +
                                              order?.totalToPay[0]
                                                ?.codeCurrency}
                                          </dd>
                                        </div>

                                        <div className="col-span-2">
                                          <div className="flex-auto space-y-4 divide-y divide-gray-200 text-sm text-gray-600 md:grid md:grid-cols-2 md:gap-x-6 md:space-y-0 md:divide-y-0 lg:flex-none lg:gap-x-8">
                                            <div className=" col-span-1  pt-4 md:block md:pt-0">
                                              <dt className="font-medium text-gray-900 mt-2  ">
                                                Creada
                                              </dt>
                                              <dd className="md:mt-1">
                                                <time
                                                  className="text-gray-500 font-semibold"
                                                  dateTime={order.createdAt}
                                                >
                                                  {formatDateTime(
                                                    order.createdAt
                                                  )}
                                                </time>
                                              </dd>
                                            </div>

                                            <div className=" col-span-1  pt-4 font-medium text-gray-900 md:block md:pt-0">
                                              <dt className="mt-2  whitespace-nowrap">
                                                Estado
                                              </dt>
                                              <dd className="md:mt-1 text-gray-500 font-semibold  whitespace-nowrap">
                                                {filterOrdenStatus(
                                                  order?.status
                                                )}
                                              </dd>
                                            </div>
                                          </div>
                                        </div>
                                      </dl>
                                      <div className="mb-2 mt-6 flow-root divide-y divide-gray-200 border-t border-gray-200">
                                        {order.selledProducts.map(
                                          (product) => {
                                            const matchedProduct =
                                              findMatchingProduct(
                                                product.name,
                                                products
                                              );

                                            return (
                                              <div
                                                key={product.id}
                                                className="pt-6 sm:flex sm:flex-col"
                                              >
                                                <div className="flex space-x-4 sm:min-w-0 sm:flex-1 sm:space-x-6 lg:space-x-8">
                                                  <img
                                                    src={
                                                      product.image?.src
                                                        ? product.image.src
                                                        : imageDefault
                                                    }
                                                    alt={
                                                      product.image?.src
                                                        ? product.image.src
                                                        : imageDefault
                                                    }
                                                    className="h-20 w-20 flex-none rounded-md object-cover object-center sm:h-32 sm:w-32"
                                                  />
                                                  <div className="min-w-0 flex-1 pt-1.5 sm:pt-0">
                                                    <h3 className="text-sm font-medium text-gray-900">
                                                      {product.name}
                                                    </h3>
                                                    <p className="truncate text-sm text-gray-500">
                                                      <span>
                                                        {product.quantity +
                                                          " U"}
                                                      </span>
                                                    </p>
                                                    <p className="mt-1 font-medium text-gray-900">
                                                      {product?.priceUnitary
                                                        ?.amount +
                                                        " " +
                                                        product?.priceUnitary
                                                          ?.codeCurrency}
                                                    </p>
                                                  </div>
                                                </div>
                                                {matchedProduct !==
                                                  undefined && (
                                                    <div className="mt-6 sm:mt-4 flex items-center justify-end w-full">
                                                      <button
                                                        type="button"
                                                        className="flex w-32 sm:w-40 whitespace-nowrap h-10 items-center justify-center rounded-md border border-transparent  px-2.5 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-600 bg-orange-500 focus:outline-none focus:ring-2  focus:ring-offset-2 sm:flex-grow-0"
                                                        onClick={() =>
                                                          addCart(
                                                            matchedProduct
                                                          )
                                                        }
                                                      >
                                                        Comprar otra vez
                                                      </button>
                                                    </div>
                                                  )}
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                      {order?.paymentGateway?.code ===
                                        "G_TROPIPAY" && (
                                          <div className="mt-6 sm:mt-4 flex items-center justify-end w-full">
                                            <button
                                              type="button"
                                              className="flex w-full whitespace-nowrap h-10 items-center justify-center rounded-md border border-transparent  px-2.5 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 bg-blue-500 focus:outline-none focus:ring-2  focus:ring-offset-2 sm:flex-grow-0"
                                              onClick={() => {
                                                CreateTropipayLink(order.id);
                                              }}
                                            >
                                              Volver a generar link de pago
                                            </button>
                                          </div>
                                        )}
                                      {(order.status === "PAYMENT_PENDING" ||
                                        order.status === "CREATED") && (
                                          <div className="mt-6 sm:mt-4 flex items-center justify-end w-full">
                                            <button
                                              type="button"
                                              className="flex w-full whitespace-nowrap h-10 items-center justify-center rounded-md border border-transparent  px-2.5 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 bg-red-600 focus:outline-none focus:ring-2  focus:ring-offset-2 sm:flex-grow-0"
                                              onClick={() => {
                                                setDeleteOrderModal({
                                                  state: true,
                                                  id: order.id,
                                                });
                                              }}
                                            >
                                              Cancelar orden
                                            </button>
                                          </div>
                                        )}
                                    </section>
                                  ))
                                  .slice(0, 1)}
                              </>
                            ) : (
                              <p className="text-center text-slate-400 my-10">
                                No hay ordenes registradas aún
                              </p>
                            )}
                          </div> */}
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default LoginAndRegisterPanel
