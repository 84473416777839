import { createSlice } from "@reduxjs/toolkit";
// import { closeSystem } from "../actions/globals";
import { BusinessInterface, CategoryInterface, ProductInterface, StoreSections, User } from "../../interfaces/ServerInterfaces";

interface InitialState {
  key: { token: string; refresh_token: string } | null;
  businessId: number | null;
  staticBar: boolean;
  user: User | null;
  currentCurrency: string | null;
  minimun_amount_to_buy_with_delivery: { amount: number, codeCurrency: string }[];
  availableCurrencies: Array<{
    id: number;
    exchangeRate: number;
    isMain: boolean;
    name: string;
    code: string;
    symbol: string
  }>;
  products: Array<StoreSections>;
  suggestedProducts: Array<ProductInterface>;
  onSaleProducts: Array<ProductInterface>;
  products_with_no_salesCategories: StoreSections | undefined;
  configurationsKey: Array<{
    id: number;
    key: string;
    name: string;
    value: string;
  }>;
  saleCategories: Array<CategoryInterface>;
  business: BusinessInterface | undefined;
  actualSlug: string | undefined;
}

const initialState: InitialState = {
  key: null,
  businessId: null,
  staticBar: true,
  user: null,
  currentCurrency: null,
  minimun_amount_to_buy_with_delivery: [],
  availableCurrencies: [],
  products: [],
  suggestedProducts: [],
  onSaleProducts: [],
  products_with_no_salesCategories: undefined,
  configurationsKey: [],
  saleCategories: [],
  business: undefined,
  actualSlug: undefined,
};

const sessionSlice = createSlice({
  initialState,
  name: "session",
  reducers: {
    setKeys: (state, action) => ({ ...state, key: action.payload }),
    setCurrentCurrency: (state, action) => ({ ...state, currentCurrency: action.payload }),
    setUser: (state, action) => ({ ...state, user: action.payload }),
    setBusinessId: (state, action) => ({
      ...state,
      businessId: action.payload,
    }),
    set_minimun_amount_to_buy_with_delivery: (state, action) => ({
      ...state,
      minimun_amount_to_buy_with_delivery: action.payload,
    }),
    set_availableCurrencies: (state, action) => ({
      ...state,
      availableCurrencies: action.payload,
    }),
    changeStaticBar: (state) => ({ ...state, staticBar: !state.staticBar }),
    setProducts: (state, action) => ({ ...state, products: action.payload }),
    setSuggestedProducts: (state, action) => ({ ...state, suggestedProducts: action.payload }),
    setOnSaleProducts: (state, action) => ({ ...state, onSaleProducts: action.payload }),
    set_products_with_no_salesCategories: (state, action) => ({
      ...state,
      products_with_no_salesCategories: action.payload,
    }),
    setConfigurationsKey: (state, action) => ({
      ...state,
      configurationsKey: action.payload,
    }),
    setSaleCategories: (state, action) => ({
      ...state,
      saleCategories: action.payload,
    }),

    setBusiness: (state, action) => ({
      ...state,
      business: action.payload,
    }),
    setActualSlug: (state, action) => ({
      ...state,
      actualSlug: action.payload,
    }),
  },
  //   extraReducers: (builder) =>
  //     builder.addCase(closeSystem, (state) => ({ ...state, key: null })),
});

export const { setKeys,
  setUser,
  setBusinessId,
  changeStaticBar,
  setCurrentCurrency,
  set_minimun_amount_to_buy_with_delivery,
  set_availableCurrencies,
  setProducts,
  setSuggestedProducts,
  setOnSaleProducts,
  set_products_with_no_salesCategories,
  setConfigurationsKey,
  setSaleCategories,
  setBusiness,
  setActualSlug,
} =
  sessionSlice.actions;
export default sessionSlice.reducer;
