/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  faTimes,
  faHeart,
  faTrashAlt,
  faPlusCircle,
  faMinusCircle,
  faTags,
  faBagShopping,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProductCartInterface } from "../../interfaces/LocalInterfaces";
import imageDefault from "../../assets/jpg/no-imagen-shop.jpg";
import {
  printPriceWithCommasAndPeriods,
  scrollToTop,
} from "../../utils/functions";
import { useAppSelector } from "../../store/hooks";
import { toast } from "react-toastify";

interface CartShoppingPanelInterface {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cartShopping: ProductCartInterface[];
  removeQuantityCart: Function;
  deletedProductFromCart: Function;
  quantityProduct: Function;
  addProductCart: Function;
  actualView: string;
  subTotal: {
    prices: number;
    codeCurrency: string;
  }[];
  availabilityAlert: boolean;
  setActualView: React.Dispatch<React.SetStateAction<string>>;
  setIsLoggingToPay: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModalLogIn: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModalCheckoutForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const CartShoppingPanel = ({
  open,
  setOpen,
  cartShopping,
  removeQuantityCart,
  deletedProductFromCart,
  quantityProduct,
  addProductCart,
  actualView,
  subTotal,
  availabilityAlert,
  setActualView,
  setIsLoggingToPay,
  setShowModalLogIn,
  setShowModalCheckoutForm,
}: CartShoppingPanelInterface) => {
  const {
    business,
    minimun_amount_to_buy_with_delivery,
    currentCurrency,
    user,
  } = useAppSelector((state) => state.session);

  const when_shop_create_preorder =
    business?.configurationsKey.filter(
      (config) => config.key === "when_shop_create_preorder"
    )[0].value === "true";
  const enable_to_sale_in_negative = business?.configurationsKey.some(
    (config) => config.key === "enable_to_sale_in_negative"
  )
    ? business?.configurationsKey.filter(
        (config) => config.key === "enable_to_sale_in_negative"
      )[0].value === "true"
    : false;

  const minimum_buy_alert =
    minimun_amount_to_buy_with_delivery.length > 0 &&
    minimun_amount_to_buy_with_delivery?.find(
      (element) => element.codeCurrency === currentCurrency
    )?.amount! >= subTotal[0]?.prices;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Bolsa de compra
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => setOpen(false)}
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          {cartShopping.length > 0 ? (
                            <ul
                              role="list"
                              className="-my-4 divide-y divide-gray-200"
                            >
                              {cartShopping.map((product) => (
                                <li key={product.id} className="flex py-4">
                                  <div className="h-16 w-16 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                    <img
                                      src={
                                        product.images
                                          ? product?.images.src
                                          : imageDefault
                                      }
                                      alt={product.name}
                                      className="h-full w-full object-cover object-center"
                                    />
                                  </div>

                                  <div className="ml-4 flex flex-1 flex-col">
                                    <div>
                                      <div className="flex justify-between text-base font-medium text-gray-900">
                                        <div className="flex flex-row   w-32 overflow-hidden  ">
                                          <h3 className="">
                                            {product.onSale && (
                                              <FontAwesomeIcon
                                                icon={faTags}
                                                className="ml-1 text-orange-300"
                                                size="sm"
                                                aria-hidden="true"
                                              />
                                            )}
                                            {product?.suggested && (
                                              <FontAwesomeIcon
                                                icon={faHeart}
                                                className="ml-1 text-orange-300"
                                                size="sm"
                                                aria-hidden="true"
                                              />
                                            )}{" "}
                                            {product.name}
                                          </h3>
                                        </div>
                                        <div className="ml-4">
                                          {product.onSale && (
                                            <p className={``}>
                                              {printPriceWithCommasAndPeriods(
                                                product?.onSalePrice?.amount *
                                                  product.quantity
                                              ) +
                                                " " +
                                                product?.onSalePrice
                                                  ?.codeCurrency}{" "}
                                            </p>
                                          )}
                                          <p
                                            className={` ${
                                              product.onSale &&
                                              "line-through text-slate-500"
                                            }`}
                                          >
                                            {`${printPriceWithCommasAndPeriods(
                                              product.price.price *
                                                product.quantity
                                            )} ${product.price.codeCurrency}`}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex flex-1 mt-2 items-end justify-between text-sm">
                                      <div className="flex flex-row items-center">
                                        <FontAwesomeIcon
                                          icon={faMinusCircle}
                                          className="-ml-1 mx-2 text-gray-500 hover:text-rose-500"
                                          size="xl"
                                          aria-hidden="true"
                                          onClick={() => {
                                            if (product.quantity !== 1) {
                                              removeQuantityCart(product);
                                            } else {
                                              deletedProductFromCart(product);
                                            }
                                          }}
                                        />
                                        <input
                                          value={product.quantity}
                                          onChange={(e) =>
                                            quantityProduct(
                                              product,
                                              parseInt(e.target.value)
                                            )
                                          }
                                          name=""
                                          id=""
                                          autoComplete="quantity"
                                          className="block w-10  rounded-md border-0 p-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm "
                                        />
                                        <FontAwesomeIcon
                                          icon={faPlusCircle}
                                          className=" mx-2 text-gray-500 hover:text-orange-500"
                                          size="xl"
                                          aria-hidden="true"
                                          onClick={() => {
                                            addProductCart(product);
                                          }}
                                        />

                                        {product.type === "ADDON" ||
                                        product.type === "MENU" ||
                                        product.type === "SERVICE" ||
                                        when_shop_create_preorder ||
                                        enable_to_sale_in_negative
                                          ? false
                                          : product.quantity >
                                              product.quantityInStock && (
                                              <span className="text-red-500">
                                                La cantidad solicitada excede a
                                                la disponible
                                              </span>
                                            )}
                                      </div>

                                      <div className="flex">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            deletedProductFromCart(product)
                                          }
                                          className="font-medium text-gray-600 hover:text-rose-500"
                                        >
                                          <FontAwesomeIcon
                                            icon={faTrashAlt}
                                            className="-ml-1 mx-2"
                                            size="lg"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <div>
                              <p className="mt-64 text-center text-lg text-slate-400">
                                No ha seleccionado ningún producto. Agregue uno
                                para continuar.
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {actualView !== "checkout-form" && (
                      <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                        <div className="flex justify-between text-base font-medium text-gray-900">
                          <p>Subtotal</p>
                          <p>
                            {subTotal.map((st, key) => (
                              <p className="" key={key}>
                                {printPriceWithCommasAndPeriods(st.prices)}{" "}
                                {st.codeCurrency}
                              </p>
                            ))}
                          </p>
                        </div>

                        {!business?.enableManagementOrders && (
                          <p className="mt-8 text-base text-gray-400 md:order-1 md:mt-0">
                            * El precio final puede variar debido a los costos
                            de envío o si solicita una personalización del
                            producto. Por favor, confírmelo con el negocio.
                          </p>
                        )}

                        {minimun_amount_to_buy_with_delivery.length > 0 &&
                          minimun_amount_to_buy_with_delivery?.find(
                            (element) =>
                              element.codeCurrency === currentCurrency
                          )?.amount! >= subTotal[0]?.prices && (
                            <p className="mt-8 text-base text-white md:order-1 md:mt-0 bg-red-500 p-2 rounded-md opacity-80">
                              Su orden debe de ser mayor que{" "}
                              {printPriceWithCommasAndPeriods(
                                minimun_amount_to_buy_with_delivery?.find(
                                  (element) =>
                                    element.codeCurrency === currentCurrency
                                )?.amount
                              ) +
                                " " +
                                minimun_amount_to_buy_with_delivery?.find(
                                  (element) =>
                                    element.codeCurrency === currentCurrency
                                )?.codeCurrency}{" "}
                              {business?.configurationsKey.filter(
                                (config) =>
                                  config.key === "when_shop_create_preorder"
                              )[0].value === "true"
                                ? "para poder finalizar la orden"
                                : business?.configurationsKey.filter(
                                    (config) =>
                                      config.key === "enable_pick_up_in_store"
                                  )[0].value === "true"
                                ? "para poder solicitar mensajería en la compra."
                                : "para realizar la compra."}
                            </p>
                          )}

                        <div className="mt-6">
                          <div
                            onClick={() => {
                              if (business?.enableManagementOrders) {
                                if (
                                  (!availabilityAlert ||
                                    when_shop_create_preorder ||
                                    enable_to_sale_in_negative) &&
                                  cartShopping.length > 0 &&
                                  (business?.configurationsKey.filter(
                                    (config) =>
                                      config.key === "enable_pick_up_in_store"
                                  )[0].value === "true"
                                    ? true
                                    : minimun_amount_to_buy_with_delivery.length <
                                      1
                                    ? true
                                    : minimun_amount_to_buy_with_delivery.find(
                                        (element) =>
                                          element.codeCurrency ===
                                          currentCurrency
                                      )?.amount! < subTotal[0]?.prices)
                                ) {
                                  if (user !== undefined && user !== null) {
                                    if (
                                      when_shop_create_preorder &&
                                      minimum_buy_alert
                                    ) {
                                      toast.error(
                                        "No puede finalizar la compra"
                                      );
                                    } else if (
                                      when_shop_create_preorder &&
                                      !minimum_buy_alert
                                    ) {
                                      setOpen(false);
                                      setActualView("checkout-form");
                                      scrollToTop();
                                    } else {
                                      setOpen(false);
                                      setActualView("checkout-form");
                                      scrollToTop();
                                    }
                                  } else {
                                    setIsLoggingToPay(true);
                                    setShowModalLogIn(true);
                                  }
                                }
                              } else {
                                if (
                                  !availabilityAlert &&
                                  cartShopping.length > 0 &&
                                  (minimun_amount_to_buy_with_delivery.length <
                                  1
                                    ? true
                                    : minimun_amount_to_buy_with_delivery.find(
                                        (element) =>
                                          element.codeCurrency ===
                                          currentCurrency
                                      )?.amount! < subTotal[0]?.prices)
                                ) {
                                  // setOpen(false)
                                  setShowModalCheckoutForm(true);
                                }
                              }
                            }}
                            className={`${
                              (!availabilityAlert ||
                                when_shop_create_preorder ||
                                enable_to_sale_in_negative) &&
                              cartShopping.length > 0 &&
                              (business?.enableManagementOrders
                                ? business?.configurationsKey.filter(
                                    (config) =>
                                      config.key === "enable_pick_up_in_store"
                                  )[0].value === "true"
                                  ? true
                                  : minimun_amount_to_buy_with_delivery.length <
                                    1
                                  ? true
                                  : minimun_amount_to_buy_with_delivery.find(
                                      (element) =>
                                        element.codeCurrency === currentCurrency
                                    )?.amount! < subTotal[0]?.prices
                                : minimun_amount_to_buy_with_delivery.length < 1
                                ? true
                                : minimun_amount_to_buy_with_delivery.find(
                                    (element) =>
                                      element.codeCurrency === currentCurrency
                                  )?.amount! < subTotal[0]?.prices)
                                ? "hover:bg-orange-600 bg-orange-500 cursor-pointer"
                                : "bg-orange-200"
                            } flex items-center justify-center rounded-md border border-transparent  px-6 py-3 text-base font-medium text-white shadow-sm `}
                          >
                            <FontAwesomeIcon
                              icon={faBagShopping}
                              className="mx-2 text-white"
                              size="sm"
                              aria-hidden="true"
                            />

                            {business?.enableManagementOrders
                              ? user === undefined || user === null
                                ? "Ir a iniciar sesión"
                                : "Finalizar compra"
                              : "Ordenar vía whatsapp"}
                          </div>
                          {business?.enableManagementOrders &&
                            (user === undefined || user === null) && (
                              <p className="mt-6 flex justify-center text-sm font-medium text-red-500">
                                Para finalizar su compra debe iniciar sesión
                              </p>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CartShoppingPanel;
