import { createRoot } from 'react-dom/client';
import { persistor } from './store/store';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { MainRoute } from './routes/MainRoute';
import { Providers } from './store/Providers';
import Loading from './components/Loading';
import { PersistGate } from 'redux-persist/integration/react'

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Providers>
    <PersistGate persistor={persistor} loading={<Loading />}>
      <MainRoute />
    </PersistGate>
  </Providers>
);

reportWebVitals();
