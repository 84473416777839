import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  setRegions,
  setPaymentWay,
  setProvinces,
  setMunicipalities,
} from "../store/slices/FormSlice";
import axios from "axios";

export const useServerForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const { key, businessId } = useAppSelector((state) => state.session);

  const findAllRegions = async () => {
    setIsLoading(true);
    try {
      const response: any = await axios.get(
        `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}/marketplace/shipping/region?all_data=true`,
        {
          headers: {
            "X-App-Origin": "Tecopos-Marketplace",
            "X-App-Authorization": `Bearer ${process.env.REACT_APP_VERSION_MARKETPLACEACCESSKEY}`,
            "X-App-BusinessId": businessId,
            Authorization: `Bearer ${key?.token}`,
          },
        }
      );
      // setData(response.data.items);
      dispatch(setRegions(response.data.items));
    } catch (error: any) {
      console.log("Error fetching data:", error);
      setError(error);
    }
    setIsLoading(false);
  };
  const findAllCountriesProvincesAndMunicipalities = async () => {
    setIsLoading(true);

    const headers = {
      headers: {
        "X-App-Origin": "Tecopos-Marketplace",
        "X-App-Authorization": `Bearer ${process.env.REACT_APP_VERSION_MARKETPLACEACCESSKEY}`,
        "X-App-BusinessId": businessId,
        Authorization: `Bearer ${key?.token}`,
      },
    };
    const queries = [
      axios.get(
        `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}/public/provinces?countryId=54`,
        headers
      ),
      axios.get(
        `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}/public/municipalities?all_data=true`,
        headers
      ),
    ];
    try {
      Promise.all(queries).then((resp) => {
        dispatch(setProvinces(resp[0].data.items));
        dispatch(setMunicipalities(resp[1].data.items));
      });
    } catch (error: any) {
      console.log("Error fetching data:", error);
      setError(error);
    }
    setIsLoading(false);
  };

  const getPaymentWays = async () => {
    setIsLoading(true);
    try {
      const response: any = await axios.get(
        `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}/marketplace/paymentgateway`,
        {
          headers: {
            "X-App-Origin": "Tecopos-Marketplace",
            "X-App-Authorization": `Bearer ${process.env.REACT_APP_VERSION_MARKETPLACEACCESSKEY}`,
            "X-App-BusinessId": businessId,
            Authorization: `Bearer ${key?.token}`,
          },
        }
      );
      // setData(response.data.items);
      dispatch(setPaymentWay(response.data));
    } catch (error: any) {
      console.log("Error fetching data:", error);
      setError(error);
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    error,
    findAllRegions,
    getPaymentWays,
    findAllCountriesProvincesAndMunicipalities,
  };
};
