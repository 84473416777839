import { faHeart, faMugHot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BusinessInterface } from "../interfaces/ServerInterfaces";
import APIServer from "../services/APIServer";

export const App = () => {
  const [listBusiness, setListBusiness] = useState<Array<BusinessInterface>>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  

  useEffect(() => {
    (async () => {
      Promise.all([APIServer.get(`/public/business/?per_page=100`)])
        .then((resp) => {
          setListBusiness(resp[0].data.items);

          setIsLoading(false);
        })
        .catch((error) => {
          const message = error.response.data.message;
          if (message) {
          } else {
          
          }
          setIsLoading(false);
        });
    })();
  }, []);

  return (
    <div className="App">
      <div className="bg-white pb-8 sm:pb-12 lg:pb-12">
        <div className="overflow-hidden pt-8 sm:pt-12 lg:relative lg:py-48">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-24 lg:px-8">
            <div>
              <div>
                <img
                  className="h-16 w-auto"
                  src={require("../assets/png/logo512.png")}
                  alt="Tecopos Tienda"
                />
              </div>
              <div className="mt-10">
                <div className="mt-6 sm:max-w-xl">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    Si puedes soñarlo, puedes venderlo con Tecopos Tienda
                  </h1>
                  <p className="mt-6  text-xl text-gray-500">
                  Tecopos-Tienda, es una herramienta 100% gratis brindada por Tecopos para mantener a los clientes actualizados de los productos en venta de los negocios registrados. 
                  </p>
                  <p className="mt-1  text-xl text-gray-500">
                  Tecopos-Tienda le permite a tus clientes realizar pedidos desde cualquier dispositivo, enviando la orden directo a una cuenta de WhatsApp o WhatsAppBusiness. 
                  </p>
                  <p className="mt-1 mb-4 text-xl text-gray-500">
                  ¡Dile adiós a esos problemas que tenías para dar a conocer tu cartera de ventas!  ¡Con Tecopos siempre ganas más! 
                  </p>
                  <a
                    href="https://api.whatsapp.com/send?phone=5359112215"
                    className="mt-8 w-full rounded-md border border-transparent  bg-gray-800 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2   sm:inline-flex sm:w-auto sm:flex-shrink-0 sm:items-center"
                  >
                    Unirme ahora
                  </a>
                </div>
                {/* <form action="#" className="mt-12 sm:flex sm:w-full sm:max-w-lg">
                <div className="min-w-0 flex-1">
                  <label htmlFor="hero-email" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="hero-email"
                    type="email"
                    className="block w-full rounded-md border border-gray-300 px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-3">
                  <button
                    type="submit"
                    className="block w-full rounded-md border border-transparent bg-orange-600 px-5 py-3 text-base font-medium text-white shadow hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:px-10"
                  >
                    Notify me
                  </button>
                </div>
              </form> */}
              </div>
            </div>
          </div>

          <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
            <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <div className="hidden sm:block">
                <div className="absolute inset-y-0 left-1/2 w-screen rounded-l-3xl bg-gray-50 lg:left-80 lg:right-0 lg:w-full" />
                <svg
                  className="absolute top-8 right-1/2 -mr-3 lg:left-0 lg:m-0"
                  width={404}
                  height={392}
                  fill="none"
                  viewBox="0 0 404 392"
                >
                  <defs>
                    <pattern
                      id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={392}
                    fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                  />
                </svg>
              </div>
              <div className="relative -mr-40 pl-4 sm:mx-auto sm:max-w-3xl sm:px-0 lg:h-full lg:max-w-none lg:pl-12">
                <img
                  className="w-full rounded-md  lg:h-full lg:w-auto lg:max-w-none"
                  src={require("../assets/png/top-nav-with-multi-column-layout-screenshot.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="bg-gray-900">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12">
            <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                {" "}
                Descubre por qué los empresarios eligen Tecopos Tienda para
                expandir su negocio.
              </h2>
              <p className="text-xl text-gray-300">
                Facilitamos a los negocios herramientas tecnológicas con el fin
                de crear mejores experiencias para sus clientes y resultados
                comerciales más exitosos. ¡TECOPOS es más que un punto de venta!
              </p>
            </div>
            <ul
              role="list"
              className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8"
            >
              {listBusiness.map((business) => (
                <li
                  key={business.id}
                  className="rounded-lg bg-gray-800 py-10 px-6 text-center xl:px-10 xl:text-left"
                >
                  <div className="space-y-6 xl:space-y-10">
                    <img
                      className="mx-auto h-32 w-32 rounded-full xl:h-40 xl:w-40"
                      src={
                        business.logo
                          ? business.logo.src
                          : require("../assets/jpg/image-default.jpg")
                      }
                      alt={business.name}
                      onClick={() => navigate(`/${business.slug}`)}
                    />
                    <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                      <div className="space-y-1 text-lg font-medium leading-6">
                        <h3 className="text-white">{business.name}</h3>
                        <p className="text-orange-400">
                          {business.businessCategory.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div> */}

      <footer className="bg-white">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="mt-8 md:order-1 md:mt-0">
            <p className="text-center text-base text-slate-900">
            &copy; {new Date().getFullYear()}Tecopos Tienda. ThreeFace. Todos los derechos
              reservados. Hecho con             
              <FontAwesomeIcon
                icon={faHeart}
                className="h-3 w-3 mx-1 text-slate-900"
                aria-hidden="true"
              />{" "}
              y{" "}
              <FontAwesomeIcon
                icon={faMugHot}
                className="h-3 w-3  text-slate-900"
                aria-hidden="true"
              />
              .{" "}
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};
