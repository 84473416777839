import React, { useState } from "react";
import {
  ProductInterface,
  StoreSections,
} from "../interfaces/ServerInterfaces";

import { CardProduct } from "./CardProduct";
import ModalProduct from "./modals/modalProduct/ModalProduct";
import { ProductCartInterface } from "../interfaces/LocalInterfaces";

interface Props {
  addCart: Function;
  storeSection: StoreSections | undefined;
  includeShop: boolean;
  cartShopping: Array<ProductCartInterface>;
  when_shop_create_preorder: boolean;
  enable_to_sale_in_negative: boolean;
}

export default function ProductForCategory({ addCart, storeSection, includeShop, cartShopping, when_shop_create_preorder, enable_to_sale_in_negative }: Props) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [seletedProduct, setSeletedProduct] = useState<ProductInterface | null>(
    null
  );

  const showProduct = (value: ProductInterface) => {
    setSeletedProduct(value);
    setShowModal(true);
  };

  if (!storeSection) {
    return <></>;
  }

  return (
    <>
      <div key={storeSection.id} className={"mt-8"}>
        <h5
          className="font-bold text-xl my-2 text-left text-slate-500"
          id={storeSection.title}
        >
          {storeSection.title}
        </h5>

        <div className="grid grid-cols-2 gap-y-3 gap-x-3 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-3 md:gap-y-6 md:gap-x-6   sm:gap-y-10 lg:grid-cols-4 lg:gap-y-6 lg:gap-x-6 xl:grid-cols-5 xl:gap-y-6 xl:gap-x-6">
          {storeSection.data.map((product, index) => (
            <CardProduct
              product={product}
              onClick={() => showProduct(product)}
              priceSystemId={1}
              cardWidth={"w-full"}
              addCart={addCart}
              includeShop={includeShop}
              key={index}
              addedToCart={cartShopping.some(prod => prod.id === product.id)}
              when_shop_create_preorder={when_shop_create_preorder}
              enable_to_sale_in_negative={enable_to_sale_in_negative}
            />
          ))}
        </div>
      </div>

      {showModal && (
        <ModalProduct
          product={seletedProduct}
          onClick={() => setShowModal(false)}
          addCart={addCart}
          includeShop={includeShop}
          when_shop_create_preorder={when_shop_create_preorder}
          enable_to_sale_in_negative={enable_to_sale_in_negative}
        />
      )}
    </>
  );
}
