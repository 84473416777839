import { faShoppingBag, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BusinessInterface, ProductInterface, StoreSections } from '../../interfaces/ServerInterfaces';
import { ProductCartInterface } from '../../interfaces/LocalInterfaces';
import imageDefault from "../../assets/jpg/no-imagen-shop.jpg"
import { useAppSelector } from '../../store/hooks';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import { classNames } from "../../utils/functions";
import { useDispatch } from 'react-redux';
import { setSearchInputValue } from "../../store/slices/resultsReducer";

interface HeaderInterface {
  setActualView: any;
  actualView: string
  business: BusinessInterface | undefined;
  cartShopping: ProductCartInterface[];
  searchInputValue: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSearchSubmit: (e: any) => void;
  setUserOpen: any;
  online_shop_main_currency: {
    value: string;
    name: string;
    href: string;
    current: boolean;
  }[];
  setCartShopping: any;
  products: StoreSections[];
  handle_online_shop_main_currency_sortOptions: (e: any) => void;
}

const Header = ({
  setActualView,
  actualView,
  business,
  cartShopping,
  searchInputValue,
  setOpen,
  handleSearchSubmit,
  setUserOpen,
  online_shop_main_currency,
  handle_online_shop_main_currency_sortOptions,
  setCartShopping,
  products
}: HeaderInterface) => {

  const dispatch = useDispatch()

  const { user } = useAppSelector(state => state.session)

  // Definir una función que busque un producto por su ID
  const findProductById = (productId: number): ProductInterface | undefined => {
    // Buscar la StoreSections que contiene el producto
    const storeSectionContainingProduct = products.find((section: { data: any[]; }) =>
      section.data.some((product: { id: number; }) => product.id === productId)
    );

    // Si encontramos la sección, devolvemos el producto correspondiente
    if (storeSectionContainingProduct) {
      return storeSectionContainingProduct.data.find((product: { id: number; }) => {
        if (product.id === productId) {
          return product
        }
      });
    }

    // Si no se encuentra la sección o el producto, devolvemos undefined
    return undefined;
  };

  return (
    <header className="  fixed w-full z-30  top-0">
      {/* Secondary navigation */}
      <div
        className={`bg-slate-900  bg-opacity-60 backdrop-blur-md backdrop-filter`}
      >
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div>

            <div className="flex h-16 items-center justify-between">
              {/* Logo (lg+) */}
              <div className="hidden lg:flex lg:flex-1 lg:items-center">
                <img
                  className="h-14 w-14 rounded-full"
                  onClick={() => {
                    setActualView("shop")
                  }}
                  src={
                    business?.logo
                      ? business?.logo.src
                      : imageDefault
                  }
                  alt=""
                />
              </div>

              {/* Logo (lg-) */}
              <p className="lg:hidden" onClick={() => {
                setActualView("shop")
              }}>
                <span className="sr-only uppercase">
                  {business ? business.name : ""}
                </span>
                <img
                  src={
                    business?.logo
                      ? business?.logo.src
                      : imageDefault
                  }
                  alt=""
                  className="h-12 w-12 rounded-full"
                />
              </p>

              <div className="flex">

                {/* <SearchInputBar /> */}
                {
                  actualView !== "checkout-form" && (
                    <form className="flex items-center ml-2" onChange={handleSearchSubmit} onSubmit={(e) => e.preventDefault()}>
                      <label className="sr-only">Buscar</label>
                      <div className="relative w-full">
                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                          <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                        </div>
                        <input
                          type="text"
                          id="simple-search"
                          className="bg-gray-50 dark:bg-opacity-60 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-slate-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Buscar"
                          autoComplete="off"
                          value={searchInputValue}
                        />
                      </div>
                      {/* <button type="submit" className="p-2.5 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"><svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg></button> */}
                    </form>
                  )
                }


                <div className="flex flex-1 items-center justify-end">
                  <div className="flex items-center ">
                    <div className="ml-4 flow-root ">
                      {business?.includeShop &&
                        <button
                          type="button"
                          onClick={() => setOpen(true)}
                          className="group -m-2 flex items-center p-2"
                        >
                          <FontAwesomeIcon
                            icon={faShoppingBag}
                            className="h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />

                          <span className="ml-2 text-sm font-medium text-white">
                            {cartShopping.length}
                          </span>
                        </button>
                      }
                    </div>
                  </div>
                </div>

                {
                  (business?.enableManagementOrders && actualView !== "checkout-form") && (
                    <div className="flex flex-1 items-center justify-end">
                      <div className="flex items-center ">
                        <div className="ml-4 flow-root ">
                          {business?.includeShop &&
                            <button
                              type="button"
                              onClick={() => setUserOpen(true)}
                              className="group -m-2 flex items-center p-2"
                            >
                              {
                                user?.avatar
                                  ? <img
                                    className="h-10 w-10 mx-2 flex-shrink-0 text-gray-300 rounded-full"
                                    src={user?.avatar.src}
                                    alt=""
                                  />
                                  : <FontAwesomeIcon
                                    icon={faUserCircle}
                                    className="h-10 w-10 mx-2 flex-shrink-0 text-gray-300"
                                    aria-hidden="true"
                                  />
                              }
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  )
                }

              </div>
            </div>
          </div>
        </div>
      </div>
      {
        actualView !== "checkout-form"
        && (
          <div className="flex w-full justify-end lg:block lg:w-auto lg:ml-32 lg:absolute lg:top-5">
            <Menu as="div" className="relative inline-block text-right mr-1 lg:mr-0 lg:ml-32 rounded-md shadow-md bg-gray-800 h-8 px-2 mt-1 lg:mt-0">
              <div>
                <Menu.Button className="group inline-flex items-center justify-end text-white mt-1">
                  {online_shop_main_currency.find(option => option.current === true)?.name}

                  {
                    online_shop_main_currency.length > 1 && (
                      <ChevronDownIcon
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-white group-hover:text-white"
                        aria-hidden="true"
                      />
                    )
                  }
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className={`${online_shop_main_currency.length === 1 ? "bg-none" : "bg-gray-900 ring-1 ring-white ring-opacity-5"} absolute right-0 z-20 mt-2 w-20 origin-top-right rounded-md  shadow-2xl  focus:outline-none`}>
                  <div className="py-1">
                    {online_shop_main_currency.length > 1 && online_shop_main_currency.map((option, index) => (
                      <Menu.Item key={option.name}>
                        {({ active }) => (
                          <span
                            className={classNames(
                              option.current ? 'font-medium text-blue-600' : 'text-gray-500',
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm cursor-pointer text-center'
                            )}
                            onClick={() => {
                              if (cartShopping.length >= 1) {
                                handle_online_shop_main_currency_sortOptions(index)

                                const aux: ProductCartInterface[] = []

                                cartShopping.forEach(cartProd => {
                                  const productFounded = findProductById(cartProd.id)
                                  if (productFounded !== undefined) {
                                    if (productFounded.prices.some((price: { codeCurrency: string | undefined; }) => price.codeCurrency === online_shop_main_currency.find((_currency, i) => i === index)?.name)) {
                                      aux.push({
                                        ...cartProd,
                                        //@ts-ignore
                                        price: {
                                          price: productFounded.prices.find((price: { codeCurrency: string | undefined; }) => price.codeCurrency === online_shop_main_currency.find((_currency, i) => i === index)?.name)?.price!,
                                          codeCurrency: online_shop_main_currency.find((_currency, i) => i === index)?.name!
                                        }
                                      })
                                    }

                                  }
                                })
                                setCartShopping(aux)
                              } else {
                                handle_online_shop_main_currency_sortOptions(index)
                              }

                              dispatch(setSearchInputValue(""))
                            }}
                          >
                            {option.name}
                          </span>
                        )}
                      </Menu.Item>
                    ))}

                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        )
      }

    </header>
  )
}

export default Header
