import { createSlice } from "@reduxjs/toolkit";
import {
  ObjectWithCode,
  PaymentInterface,
  RegionsInterface,
} from "../../interfaces/ServerInterfaces";

interface InitialInterface {
  regions: (RegionsInterface | null)[];
  paymentWay: (PaymentInterface | null)[];
  tropiPayURL: string | null;
  countries: ObjectWithCode[] | null;
  provinces: ObjectWithCode[] | null;
  municipalities: ObjectWithCode[] | null;
}

const initialState: InitialInterface = {
  regions: [],
  paymentWay: [],
  tropiPayURL: null,
  countries: null,
  provinces: null,
  municipalities: null,
};

const formSlice = createSlice({
  initialState,
  name: "form",
  reducers: {
    setRegions: (state, action) => ({
      ...state,
      regions: action.payload,
    }),
    setCountries: (state, action) => ({
      ...state,
      countries: action.payload,
    }),
    setProvinces: (state, action) => ({
      ...state,
      provinces: action.payload,
    }),
    setMunicipalities: (state, action) => ({
      ...state,
      municipalities: action.payload,
    }),
    setPaymentWay: (state, action) => ({
      ...state,
      paymentWay: action.payload,
    }),
    setTropiPayURL: (state, action) => ({
      ...state,
      tropiPayURL: action.payload,
    }),
  },
});

export const {
  setRegions,
  setPaymentWay,
  setTropiPayURL,
  setCountries,
  setProvinces,
  setMunicipalities,
} = formSlice.actions;

export default formSlice.reducer;
