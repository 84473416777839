import React from 'react'
import ProductForCategory from '../../components/ProductForCategory';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faPhone,
  faMapMarkerAlt,
  faEnvelope,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { ProductInterface } from '../../interfaces/ServerInterfaces';
import { CardProduct } from '../../components/CardProduct';
import { CardCategory } from '../../components/CardCategory';
import { setResultState, setSearchInputValue } from '../../store/slices/resultsReducer';
import { ProductCartInterface } from '../../interfaces/LocalInterfaces';
import { FloatingWhatsApp } from 'react-floating-whatsapp';


interface ShopViewInterface {
  showProduct: Function;
  addCart: Function;
  cartShopping: ProductCartInterface[];
  productResults: ProductInterface[] | undefined;
  productsFilteredByCurrencyType: Function;
  products_with_no_salesCategories_FilteredByCurrencyType: Function;
  online_shop_main_currency: {
    value: string;
    name: string;
    href: string;
    current: boolean;
  }[]
}

const ShopView = ({
  showProduct,
  addCart,
  cartShopping,
  productsFilteredByCurrencyType,
  productResults,
  products_with_no_salesCategories_FilteredByCurrencyType,
  online_shop_main_currency
}: ShopViewInterface) => {

  const dispatch = useAppDispatch();

  const { business, saleCategories, products_with_no_salesCategories, onSaleProducts, suggestedProducts } = useAppSelector(state => state.session)

  const when_shop_create_preorder =
    business?.configurationsKey.filter(
      (config) => config.key === "when_shop_create_preorder"
    )[0].value === "true";

  const enable_to_sale_in_negative = business?.configurationsKey.some(
    (config) => config.key === "enable_to_sale_in_negative"
  )
    ? business?.configurationsKey.filter(
      (config) => config.key === "enable_to_sale_in_negative"
    )[0].value === "true"
    : false;


  const searchInputValue = useAppSelector(
    state => state.UI.searchInputValue
  );

  const searchResultsActive = useAppSelector(state => state.UI.value);


  const suggestedProductsFilteredByCurrencyType = () => {
    return suggestedProducts.filter((prod) => {
      if (prod.onSale) {
        return (
          prod?.onSalePrice?.codeCurrency ===
          online_shop_main_currency.filter((currency) => currency.current)[0]
            .value
        );
      } else {
        return prod.prices.some(
          (price) =>
            price.codeCurrency ===
            online_shop_main_currency.filter((currency) => currency.current)[0]
              .value
        );
      }
    });
    return []
  };

  const onSaleProductsFilteredByCurrencyType = () => {
    return onSaleProducts.filter((prod) => {
      if (prod.onSale) {
        return (
          prod?.onSalePrice?.codeCurrency ===
          online_shop_main_currency?.filter((currency) => currency.current)[0]
            .value
        );
      } else {
        return prod?.prices?.some(
          (price) =>
            price?.codeCurrency ===
            online_shop_main_currency.filter((currency) => currency.current)[0]
              .value
        );
      }
    });
    return []
  };

  const slideLeft = () => {
    let slider: any = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = () => {
    let slider: any = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  const slideLeftSuggestedProducts = () => {
    let slider: any = document.getElementById("sliderSuggested");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRightSuggestedProducts = () => {
    let slider: any = document.getElementById("sliderSuggested");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  const slideLeftOfertedProducts = () => {
    let slider: any = document.getElementById("sliderOferted");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRightOfertedProducts = () => {
    let slider: any = document.getElementById("sliderOferted");
    slider.scrollLeft = slider.scrollLeft + 500;
  };


  return (
    <>
      <div className="relative bg-slate-900">
        {/* Decorative image and overlay */}
        <div
          aria-hidden="true"
          className="absolute inset-0 overflow-hidden"
        >
          <img
            src={business?.banner ? business?.banner?.src : ""}
            alt=""
            className="h-full w-full object-cover object-center"
          />
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-0 bg-slate-900 opacity-50"
        />

        {/* Navigation */}
        <div className="relative mx-auto flex max-w-3xl flex-col items-center py-32 px-6 text-center sm:py-64 lg:px-0">
          <h1 className="text-4xl uppercase font-bold tracking-tight text-white lg:text-6xl">
            {business ? business?.name : ""}
          </h1>
          <p className="mt-4 text-xl text-white">
            {business ? business?.promotionalText : ""}
          </p>
        </div>
      </div>
      <section aria-labelledby=" sale-heading  col-start-1 col-end-3">
        <div className="overflow-hidden pt-32 grid gap-y-16 sm:-mt-60 ">
          <div className="bg-white">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div
                className={`relative ${business && business?.images?.length > 5
                  ? "pt-48"
                  : "-pt-8"
                  }  sm:pt-48 pb-16 sm:pb-24`}
              >
                <div className=" sm:w-2/4 w-auto  ">
                  {business?.description && (
                    <>
                      <h2
                        id="sale-heading"
                        className="text-2xl font-bold tracking-tight text-slate-900 md:text-3xl"
                      >
                        ¿Quiénes somos?
                      </h2>

                      <p className="text-base mt-2 text-gray-900">
                        {business?.description}
                      </p>
                    </>
                  )}
                  {business?.openHours && (
                    <div className="flex mt-5 flex-row items-center">
                      <FontAwesomeIcon
                        icon={faClock}
                        className="h-4 w-4 mr-2 text-slate-500"
                        aria-hidden="true"
                      />
                      <p className="text-base font-medium  text-gray-900">
                        {business?.openHours}
                      </p>
                    </div>
                  )}

                  {business?.phones && business?.phones?.length > 0 && (
                    <div className="flex mt-2 flex-row items-center">
                      <FontAwesomeIcon
                        icon={faPhone}
                        className="h-4 w-4 mr-2 text-slate-500"
                        aria-hidden="true"
                      />
                      <p className="text-base font-medium  text-gray-900">
                        {business?.phones
                          .map((item) => item.number)
                          .join(` ,`)}
                      </p>
                    </div>
                  )}

                  {business?.email && (
                    <div className="flex mt-2 flex-row items-center">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="h-4 w-4 mr-2 text-slate-500"
                        aria-hidden="true"
                      />
                      <p className="text-base font-medium  text-gray-900">
                        {" "}
                        {business?.email}
                      </p>
                    </div>
                  )}

                  {business?.address && (
                    <div className="flex mt-2 flex-row items-center">
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        className="h-4 w-4 mr-2 text-slate-500"
                        aria-hidden="true"
                      />
                      <p className="text-base font-medium  text-gray-900">
                        {" "}
                        {(business?.address?.street_1
                          ? business?.address?.street_1 + ", "
                          : "") +
                          (business?.address?.street_2
                            ? business?.address?.street_2 + ", "
                            : "") +
                          (business?.address?.city
                            ? business?.address.city + ", "
                            : "") +
                          (business?.address?.municipality?.name
                            ? business?.address.municipality.name + ", "
                            : "") +
                          (business?.address?.province?.name
                            ? business?.address?.province?.name + ", "
                            : "") +
                          (business?.address?.country?.name
                            ? business?.address?.country?.name
                            : "")}
                      </p>
                    </div>
                  )}

                  <div className="flex mt-2 flex-row items-center">
                    {business?.socialNetworks.map((item) => (
                      <a
                        key={item.user}
                        href={item.url}
                        className="text-slate-400 hover:text-slate-500 mr-2"
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          className="h-6 w-6"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d={
                              item.type === "FACEBOOK"
                                ? "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                : item.type === "WHATSAPP"
                                  ? "M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
                                  : item.type === "TWITTER"
                                    ? "M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                                    : "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                            }
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    ))}
                  </div>
                </div>

                {business && business?.images?.length >= 6 && (
                  <div className="absolute mt-5 -top-32 left-1/2 -translate-x-1/2 transform sm:top-6 sm:translate-x-0">
                    <div className="ml-24 flex min-w-max space-x-6 sm:ml-3 lg:space-x-8">
                      <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                        <div className="flex-shrink-0 bg-white rounded-lg">
                          <img
                            className="h-64 w-64 rounded-lg hover:opacity-60 object-cover md:h-72 md:w-72"
                            src={business?.images[0].src}
                            alt=""
                          // onClick={() => showGallery(0)}
                          />
                        </div>

                        <div className=" mt-6 flex-shrink-0 sm:mt-0 bg-white rounded-lg">
                          <img
                            className="h-64 w-64 hover:opacity-60 rounded-lg object-cover md:h-72 md:w-72"
                            src={business?.images[1].src}
                            alt=""
                          // onClick={() => showGallery(1)}
                          />
                        </div>
                      </div>
                      <div className="flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                        <div className="flex-shrink-0 bg-white rounded-lg ">
                          <img
                            className="h-64  hover:opacity-60 w-64 rounded-lg object-cover md:h-72 md:w-72"
                            src={business?.images[2].src}
                            alt=""
                          // onClick={() => showGallery(2)}
                          />
                        </div>

                        <div className="mt-6 flex-shrink-0 sm:mt-0 bg-white rounded-lg">
                          <img
                            className="h-64 w-64 hover:opacity-60 rounded-lg object-cover md:h-72 md:w-72"
                            src={business?.images[3].src}
                            alt=""
                          // onClick={() => showGallery(3)}
                          />
                        </div>
                      </div>
                      <div className="flex space-x-6  sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                        <div className="flex-shrink-0 bg-white rounded-lg">
                          <img
                            className="h-64 w-64 hover:opacity-60 rounded-lg object-cover md:h-72 md:w-72"
                            src={business?.images[4].src}
                            alt=""
                          // onClick={() => showGallery(4)}
                          />
                        </div>

                        <div className="mt-6 flex-shrink-0 sm:mt-0 bg-white rounded-lg">
                          <img
                            className="h-64 w-64 hover:opacity-60 rounded-lg object-cover md:h-72 md:w-72"
                            src={business?.images[5].src}
                            alt=""
                          // onClick={() => showGallery(5)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <main>
        {/* Suggested product section */}
        {suggestedProductsFilteredByCurrencyType()?.length > 0 && (
          <section
            aria-labelledby="category-heading"
            className="pt-12 mb-5  sm:pt-16 xl:mx-auto  xl:max-w-full xl:px-8"
          >
            <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 ">
              <h2
                id="category-heading"
                className="text-2xl font-bold tracking-tight text-slate-900"
              >
                Para ti
              </h2>
            </div>

            <div className="mt-4  ">
              <div className="-my-2">
                <div className="   sm:px-6 ">
                  <div className="relative flex items-center scrollbar-hide">
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className="opacity-50 mx-2 cursor-pointer hover:opacity-100 "
                      onClick={slideLeftSuggestedProducts}
                      size={"2x"}
                    />

                    <div
                      id="sliderSuggested"
                      className="w-full  h-full  overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-5  space-x-8 px-4 sm:px-6 lg:px-2 "
                    >
                      {suggestedProductsFilteredByCurrencyType().map(
                        (item: any, index: number) => (
                          <CardProduct
                            product={item}
                            onClick={() => showProduct(item)}
                            priceSystemId={1}
                            cardWidth="w-52"
                            addCart={addCart}
                            includeShop={
                              business?.includeShop ? true : false
                            }
                            key={index}
                            addedToCart={cartShopping.some(
                              (prod) => prod.id === item.id
                            )}
                            when_shop_create_preorder={
                              when_shop_create_preorder
                            }
                            enable_to_sale_in_negative={
                              enable_to_sale_in_negative
                            }
                          />
                        )
                      )}
                    </div>

                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="opacity-50 mx-2 cursor-pointer hover:opacity-100"
                      onClick={slideRightSuggestedProducts}
                      size={"2x"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {/* onSale product section */}
        {onSaleProductsFilteredByCurrencyType()?.length > 0 && (
          <section
            aria-labelledby="category-heading"
            className="pt-12 pb-20  sm:pt-16  xl:mx-auto xl:max-w-full xl:px-8"
          >
            <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 ">
              <h2
                id="category-heading"
                className="text-2xl font-bold tracking-tight text-slate-900"
              >
                Ofertas
              </h2>
            </div>

            <div className="mt-4  ">
              <div className="-my-2">
                <div className="   sm:px-6 ">
                  <div className="relative flex items-center scrollbar-hide">
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className="opacity-50 mx-2 cursor-pointer hover:opacity-100 "
                      onClick={slideLeftOfertedProducts}
                      size={"2x"}
                    />

                    <div
                      id="sliderOferted"
                      className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-5  space-x-8 px-4 sm:px-6 lg:px-2 "
                    >
                      {onSaleProductsFilteredByCurrencyType().map(
                        (item: any, index: number) => (
                          <>
                            <CardProduct
                              product={item}
                              addCart={addCart}
                              onClick={() => showProduct(item)}
                              priceSystemId={1}
                              cardWidth="w-52"
                              includeShop={
                                business?.includeShop ? true : false
                              }
                              key={index}
                              addedToCart={cartShopping.some(
                                (prod) => prod.id === item.id
                              )}
                              when_shop_create_preorder={
                                when_shop_create_preorder
                              }
                              enable_to_sale_in_negative={
                                enable_to_sale_in_negative
                              }
                            />
                          </>
                        )
                      )}
                    </div>

                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="opacity-50 mx-2 cursor-pointer hover:opacity-100"
                      onClick={slideRightOfertedProducts}
                      size={"2x"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {saleCategories?.length > 0 && searchInputValue === "" && (
          <div className="sticky top-14 z-20 bg-white pb-5 ">
            <div className="flex items-center scrollbar-hide">
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="opacity-50 mx-2  cursor-pointer hover:opacity-100 "
                onClick={slideLeft}
                size={"2x"}
              />
              <div
                id="slider"
                className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-5  space-x-8 px-4 sm:px-6 lg:px-2 "
              >
                {saleCategories
                  .filter((saleCategory) =>
                    productsFilteredByCurrencyType().find(
                      (item: any) => item?.id === saleCategory.id
                    )
                  )
                  .map((item) => (
                    <CardCategory category={item} key={item.id} />
                  ))}
              </div>

              <FontAwesomeIcon
                icon={faChevronRight}
                className="opacity-50 mx-2 cursor-pointer hover:opacity-100"
                onClick={slideRight}
                size={"2x"}
              />
            </div>
          </div>
        )}

        {searchResultsActive ? (
          <div className="mx-auto max-w-7xl ">
            <div id="resultSection" className="flex items-center">
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="opacity-50 mx-2  cursor-pointer hover:opacity-100 "
                size={"2x"}
                onClick={() => {
                  dispatch(setResultState(false));
                  dispatch(setSearchInputValue(" "));
                }}
              />
              <h1 className="font-bold  text-slate-500 mt-10 mb-10 flex items-center text-xl sm:text-2xl lg:text-4xl">
                RESULTADOS{" "}
                <span className="text-lg sm:text-xl lg:text-3xl ml-4">
                  ({productResults?.length})
                </span>
              </h1>
            </div>
            <div className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-5  space-x-8 px-4 sm:px-6 lg:px-2 ">
              <div className="grid grid-cols-2 gap-y-3 gap-x-3 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-3 md:gap-y-6 md:gap-x-6 sm:gap-y-10 lg:grid-cols-4 lg:gap-y-6 lg:gap-x-6 xl:grid-cols-5 xl:gap-y-6 xl:gap-x-6">
                {productResults?.map((product) => (
                  <CardProduct
                    product={product}
                    onClick={() => showProduct(product)}
                    priceSystemId={1}
                    cardWidth={"w-full"}
                    addCart={addCart}
                    includeShop={business?.includeShop ? true : false}
                    key={product.id}
                    addedToCart={cartShopping.some(
                      (prod) => prod.id === product.id
                    )}
                    when_shop_create_preorder={when_shop_create_preorder}
                    enable_to_sale_in_negative={enable_to_sale_in_negative}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className=" mx-auto max-w-2xl py-4 px-4 sm:py-6 sm:px-6 lg:max-w-7xl lg:px-8  ">
            {saleCategories?.map((item) => (
              <ProductForCategory
                storeSection={productsFilteredByCurrencyType()?.find(
                  (i: any) => item.id === i?.id
                )}
                addCart={addCart}
                includeShop={business?.includeShop ? true : false}
                key={item.id}
                cartShopping={cartShopping}
                when_shop_create_preorder={when_shop_create_preorder}
                enable_to_sale_in_negative={enable_to_sale_in_negative}
              />
            ))}
            {products_with_no_salesCategories_FilteredByCurrencyType()?.data?.length >= 1 && (
              <ProductForCategory
                storeSection={products_with_no_salesCategories_FilteredByCurrencyType()}
                addCart={addCart}
                includeShop={business?.includeShop ? true : false}
                key={products_with_no_salesCategories?.id}
                cartShopping={cartShopping}
                when_shop_create_preorder={when_shop_create_preorder}
                enable_to_sale_in_negative={enable_to_sale_in_negative}
              />
            )}
          </div>
        )}
      </main>

      {business?.phones?.length! > 0 && (
        // <WhatsAppButton phoneNumber={business?.phones[0]?.number!} />
        <FloatingWhatsApp
          avatar={
            business?.logo !== null ? business?.logo?.src : "favicon.ico"
          }
          chatMessage={"Hola, ¿Cómo lo podemos ayudar?"}
          statusMessage={""}
          accountName={business?.name!}
          phoneNumber={
            business?.phones?.length! > 1
              ? business?.phones[0]?.number!
              : business?.phones[0]?.number!
          }
        />
      )}
    </>
  )
}

export default ShopView
