import { useState } from "react";
import query from "./APIServer";
import { OrderInterface } from "../interfaces/ServerInterfaces";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  setBussinessBeforeCreateOrder,
  setCouponDetails,
  setCouponDetailsName,
  setOrderCompleted,
  setOrders,
  setOrdersPaginate,
} from "../store/slices/OrdersSlice";
import { toast } from "react-toastify";
import { setTropiPayURL } from "../store/slices/FormSlice";
import { generateUrlParams } from "../utils/functions";
import { ProductCartInterface } from "../interfaces/LocalInterfaces";
import axios from "axios";
import { setInitProductsCar } from "../store/slices/InitSlice";

export const useServerOrders = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  const [allOrders, setAllOrders] = useState<OrderInterface[]>([]);
  const [orderDetails, setOrderDetails] = useState<OrderInterface>();

  const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  const { initProductsCar } = useAppSelector((state) => state.init);
  const { orders } = useAppSelector((state) => state.orders);
  const { key } = useAppSelector((state) => state.session);

  const manageErrors = (error: any) => {
    console.log({ error });
    if (error.status === 401) {
      toast.error(error.response?.data?.message);
      // navigate("/");
    }
    if (error.response?.data?.message) {
      if (
        error.response?.data?.message ===
        "SequelizeValidationError: notNull Violation: Price.amount cannot be null"
      ) {
        toast.error("Introduzca la dirección de envío");
      } else if (
        error.response?.data?.message ===
        "TypeError: Cannot read properties of undefined (reading 'error')"
      ) {
        toast.error(
          "Hubo un error para generar el enlace de pago. Vuelva a intentar y si el problema persiste contacte con el centro de atención de Tecopos",
          {
            position: "top-center",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        toast.error(error.response?.data?.message);
      }

      return;
    } else {
      toast.error(
        "Upss, ha ocurrido un error inesperado. \n Intente de nuevo o consulte con su administrador..."
      );
      return;
    }
  };

  const getMyOrders = async (
    filter: Record<string, string | number | boolean | null>
  ) => {
    setIsLoading(true);
    await query
      .get(`/marketplace/order/${generateUrlParams(filter)}`)
      .then((resp) => {
        dispatch(setOrders(resp.data.items));
        dispatch(
          setOrdersPaginate({
            totalItems: resp.data.totalItems,
            totalPages: resp.data.totalPages,
            currentPage: resp.data.currentPage,
          })
        );
        setAllOrders(resp.data.items);
      })
      .catch((e) =>
        setError(e.message.data ?? "Ha ocurrido un error vuelva a intentarlo")
      );
    setIsLoading(false);
  };

  const getOrderDetails = async (
    idOrder: number,
    bussinessBeforeCreateOrder?: string,
    navigate?: any
  ) => {
    setIsLoading(true);
    await query
      .get(`/marketplace/order/${idOrder}`)
      .then((resp) => {
        dispatch(setOrderCompleted(resp.data));
        setOrderDetails(resp.data);
        if (bussinessBeforeCreateOrder) {
          dispatch(setBussinessBeforeCreateOrder(bussinessBeforeCreateOrder));
        }

        if (navigate) {
          navigate("/payment_completed");
        }
        return resp.data;
      })
      .catch((e) => {
        setError(e.message.data ?? "Ha ocurrido un error vuelva a intentarlo");
      });
    setIsLoading(false);
  };

  const createNewOrder = async (
    data: any,
    bussinessBeforeCreateOrder: string,
    navigate: any
  ) => {
    if (
      data.shippingPrice !== null &&
      data.shippingPrice.amount === undefined
    ) {
      toast.error("Revise los datos de la dirección de envío introducidos");
    } else {
      setIsLoading(true);
      await query
        .post("/marketplace/order/v2", data)
        // .post("/marketplace/order", data)
        .then((resp: any) => {
          dispatch(
            setInitProductsCar(
              initProductsCar.map((element) => {
                if (element.businessName === bussinessBeforeCreateOrder) {
                  return {
                    businessName: bussinessBeforeCreateOrder,
                    initProductsCar: [],
                  };
                } else {
                  return element;
                }
              })
            )
          );

          if (orders !== undefined && orders !== null) {
            const newOrderArray = [resp.data, ...orders];
            dispatch(setOrders(newOrderArray));
          }

          dispatch(setOrderCompleted(resp.data));

          dispatch(setBussinessBeforeCreateOrder(bussinessBeforeCreateOrder));

          const dataId: number = resp.data.id;
          if (data.paymentWayCode === "G_TROPIPAY") {
            CreateTropipayLink(dataId);
          }

          navigate("/payment_completed");
        })
        .catch((e) => {
          manageErrors(e);
        });
      setIsLoading(false);
    }
  };

  const createNewPreOrder = async (
    data: any,
    bussinessBeforeCreateOrder: string,
    navigate: any
  ) => {
    if (
      data.shippingPrice !== null &&
      data.shippingPrice.amount === undefined
    ) {
      toast.error("Revise los datos de la dirección de envío introducidos");
    } else {
      setIsLoading(true);
      const dataToSend = {
        ...data,
        paymentGatewayId: 0,
      };
      await query
        .post("/marketplace/order/preorder", dataToSend)
        // .post("/shop/order", data)
        .then((resp: any) => {
          dispatch(
            setInitProductsCar(
              initProductsCar.map((element) => {
                if (element.businessName === bussinessBeforeCreateOrder) {
                  return {
                    businessName: bussinessBeforeCreateOrder,
                    initProductsCar: [],
                  };
                } else {
                  return element;
                }
              })
            )
          );

          if (orders !== undefined && orders !== null) {
            const newOrderArray = [resp.data, ...orders];
            dispatch(setOrders(newOrderArray));
          }

          dispatch(setOrderCompleted(resp.data));

          dispatch(setBussinessBeforeCreateOrder(bussinessBeforeCreateOrder));

          const dataId: number = resp.data.id;
          if (data.paymentWayCode === "G_TROPIPAY") {
            CreateTropipayLink(dataId);
          }

          navigate("/payment_completed");
        })
        .catch((e) => {
          manageErrors(e);
        });
      setIsLoading(false);
    }
  };

  const CreateTropipayLink = async (orderID: number) => {
    setIsLoading(true);
    dispatch(setTropiPayURL("tropiPayLinkLoading"));

    const data = {
      orderId: orderID,
    };

    await query
      .post("/marketplace/paymentgateway/create-tropipay-link", data)
      .then((data) => {
        dispatch(setTropiPayURL(data.data.shortUrl));
      })
      .catch((e) => {
        manageErrors(e);
        dispatch(setTropiPayURL(null));
      });

    setIsLoading(false);
  };

  const CancelOrder = async (orderID: number) => {
    setIsLoading(true);

    const raw = {
      id: orderID,
    };

    await query
      .deleteAPI(`/marketplace/order/${orderID}`, raw)
      .then((data) => {
        toast.success(
          `Orden ${
            data.data.operationNumber ?? data.data.preOperationNumber
          } eliminada correctamente`
        );
        dispatch(
          setOrders(
            orders?.map((order) =>
              order.id !== data.data.id ? order : data.data
            )
          )
        );
      })
      .catch((e) => manageErrors(e));

    setIsLoading(false);
  };

  const ApplyCouponToOrderOnline = async (
    couponCode: string,
    products: ProductCartInterface[]
  ) => {
    setIsFetching(true);
    const data = {
      coupons: [couponCode],
      listProducts: products.map((prod) => {
        if (prod.variationId !== null) {
          return {
            productId: prod.id,
            variationId: prod.variationId,
            quantity: prod.quantity,
          };
        } else {
          return {
            productId: prod.id,
            quantity: prod.quantity,
          };
        }
      }),
    };

    // eslint-disable-next-line prefer-const
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}/marketplace/coupon`,
      headers: {
        "X-App-Origin": "Tecopos-Marketplace",
        "X-App-Authorization": `Bearer ${process.env.REACT_APP_VERSION_MARKETPLACEACCESSKEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${key?.token}`,
      },
      data: JSON.stringify(data),
    };

    axios
      .request(config)
      .then((response: { data: any }) => {
        toast.success("Cupón aplicado");

        dispatch(setCouponDetails(response?.data?.couponDiscount));
        dispatch(setCouponDetailsName(couponCode));

        setIsFetching(false);
      })
      .catch((error: any) => {
        manageErrors(error);

        setIsFetching(false);
      });

    setIsFetching(false);
  };

  return {
    getMyOrders,
    getOrderDetails,
    createNewOrder,
    createNewPreOrder,
    CreateTropipayLink,
    CancelOrder,

    isLoading,
    allOrders,
    orderDetails,
    error,
    ApplyCouponToOrderOnline,
    isFetching,
  };
};
