export const orderStatus: {
  code: string;
  value: string;
  color: string;
}[] = [
  {
    code: "WITH_ERRORS",
    value: "Con errores",
    color: "red",
  },
  {
    code: "CREATED",
    value: "Creada",
    color: "blue",
  },
  {
    code: "PAYMENT_PENDING",
    value: "Pendiente de pago",
    color: "yellow",
  },
  {
    code: "IN_PROCESS",
    value: "Procesando",
    color: "blue",
  },
  {
    code: "BILLED",
    value: "Facturada",
    color: "green",
  },
  {
    code: "CANCELLED",
    value: "Cancelada",
    color: "red",
  },
  {
    code: "REFUNDED",
    value: "Reembolsada",
    color: "yellow",
  },
  {
    code: "COMPLETED",
    value: "Completada",
    color: "green",
  },
  {
    code: "IN_TRANSIT",
    value: "En tránsito",
    color: "blue",
  },
  {
    code: "DELIVERED",
    value: "Entregada",
    color: "green",
  },
];

export const customShops = [
  // {
  //   slug: "gelato",
  //   href: "https://tienda.elgelatooficial.com",
  // },
  {
    slug: "mycesta",
    href: "https://mycesta.com",
  },
  {
    slug: "celebren-mercadito",
    href: "https://celebrenstore.com/mercadito",
  },
  {
    slug: "celebren-tienda",
    href: "https://celebrenstore.com/tienda",
  },
  {
    slug: "celebren-studio",
    href: "https://celebrenstore.com/estudio",
  },
  {
    slug: "somarriba",
    href: "https://celebrenstore.com/somarriba",
  },
  {
    slug: "centro-elaboracion2",
    href: "https://mayoristas.grupoacena.com",
  },
];
