/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { SetStateAction, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Disclosure } from "@headlessui/react";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import Input from "../../components/forms/Input";
import { faMoneyBill, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import tropiPayLogo from "../../assets/png/logoTropiPay.png";
import { ProductCartInterface } from "../../interfaces/LocalInterfaces";
import imageDefault from "../../assets/jpg/no-imagen-shop.jpg";
import {
  calculateTotal,
  calculateTotalPlusDiscount,
  printPrice,
  printPriceWithCommasAndPeriods,
  removeDuplicatesAndNulls,
} from "../../utils/functions";
import { useServerOrders } from "../../services/useServerOrders";
import {
  ObjectWithCode,
  RegionsInterface,
} from "../../interfaces/ServerInterfaces";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useServerForm } from "../../services/useServerForm";
import Select from "../../components/forms/Select";
import Modal from "../../components/modals/GenericModal";
import TextArea from "../../components/forms/TextArea";
import { setTropiPayURL } from "../../store/slices/FormSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import LoadingSpin from "../../components/misc/LoadingSpin";
import { useNavigate } from "react-router-dom";
import Button from "../../components/misc/Button";
import GenericToggle from "../../components/misc/GenericToggle";
import { setCouponDetails } from "../../store/slices/OrdersSlice";
import DateInput from "../../components/forms/DateInput";
import SpinnerLoading from "../../components/misc/SpinnerLoading";
import { toast } from "react-toastify";

interface PaymentWayFormInterface {
  setActualView: any;
  products: Array<ProductCartInterface>;
  subTotal: { prices: number; codeCurrency: string }[];
  setOpen: Function;
  deletedProductFromCart: Function;
  setCartShopping: Function;
  online_shop_main_currency: string;
  enable_pick_up_in_store: boolean;
  enable_delivery: boolean;
  when_shop_create_preorder: boolean;
}

export default function PaymentWayForm({
  setActualView,
  products,
  subTotal,
  setOpen,
  deletedProductFromCart,
  setCartShopping,
  online_shop_main_currency,
  enable_pick_up_in_store,
  enable_delivery,
  when_shop_create_preorder,
}: PaymentWayFormInterface) {
  const navigate = useNavigate();

  const {
    findAllRegions,
    getPaymentWays,
    findAllCountriesProvincesAndMunicipalities,
  } = useServerForm();
  const { availableCurrencies, user } = useAppSelector(
    (state) => state.session
  );
  const { provinces: redux_provinces, municipalities: redux_municipalities } =
    useAppSelector((state) => state.form);

  const [regionData, setRegionData] = useState<RegionsInterface[]>([]);
  const [provinces, setProvinces] = useState<ObjectWithCode[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<ObjectWithCode>();
  const [municipalities, setMunicipalities] = useState<ObjectWithCode[]>([]);
  const [selectedMunicipality, setSelectedMunicipality] =
    useState<ObjectWithCode | null>();
  const [cities, setCities] = useState<string[]>([]);
  const [selectedCity, setSelectedCity] = useState<string | null>("");
  const [selectedPrice, setSelectedPrice] = useState<{
    amount: number;
    codeCurrency: string;
  } | null>(null);
  const { currentCurrency, minimun_amount_to_buy_with_delivery } = useSelector(
    (state: RootState) => state.session
  );

  const [tropiPayLoading, setTropiPayLoading] = useState<boolean>(false);

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState<boolean>(false);

  const [billingSameAsShiping, setBillingSameAsShiping] =
    useState<boolean>(true);
  const [pickUpInStore, setPickUpInStore] = useState<boolean>(
    minimun_amount_to_buy_with_delivery.find(
      (element) => element.codeCurrency === currentCurrency
    )?.amount! >= subTotal[0]?.prices
  );

  const [errorString, setErrorString] = useState<string | null>(null);

  const { regions, paymentWay } = useAppSelector((state) => state.form);

  const { handleSubmit, control, watch } = useForm();

  const {
    createNewOrder,
    createNewPreOrder,
    isLoading,
    isFetching,
    ApplyCouponToOrderOnline,
  } = useServerOrders();

  const [loading, setLoading] = useState<boolean>(false);

  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");

  const [addDiscountCode, setaddDiscountCode] = useState<boolean>(false);

  const { couponDetails, couponDetailsName } = useAppSelector(
    (state) => state.orders
  );

  const dispatch = useAppDispatch();

  const VerifyCouponCode = (values: string) => {
    ApplyCouponToOrderOnline(values, products);
  };

  useEffect(() => {
    dispatch(setTropiPayURL(null));

    const fetchData = async () => {
      await findAllRegions();

      await getPaymentWays();
    };

    const fetchPreOrderData = async () => {
      await findAllCountriesProvincesAndMunicipalities();
    };

    if (when_shop_create_preorder) {
      fetchPreOrderData();
    } else {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (products.length < 1) {
      setActualView("shop");
    }
  }, [products]);

  useEffect(() => {
    if (
      minimun_amount_to_buy_with_delivery.find(
        (element) => element.codeCurrency === currentCurrency
      )?.amount! >= subTotal[0]?.prices
    ) {
      setPickUpInStore(true);
    }
  }, [subTotal]);

  useEffect(() => {
    const regionsFiltredBySelectedShopMainCurrency = regions.map((region) => {
      if (region?.price.codeCurrency === online_shop_main_currency) {
        return region;
      } else {
        if (
          availableCurrencies.find((elem) => elem.isMain)?.code ===
          region?.price.codeCurrency
        ) {
          return {
            ...region,
            price: {
              amount:
                region?.price.amount! /
                availableCurrencies?.find(
                  (elem) => elem.code === online_shop_main_currency
                )?.exchangeRate!,
              codeCurrency: online_shop_main_currency,
            },
          };
        } else {
          return {
            ...region,
            price: {
              amount:
                (region?.price.amount! /
                  availableCurrencies?.find(
                    (elem) => elem.code === region?.price.codeCurrency
                  )?.exchangeRate!) *
                availableCurrencies?.find(
                  (elem) => elem.code === online_shop_main_currency
                )?.exchangeRate!,
              codeCurrency: online_shop_main_currency,
            },
          };
        }
      }
    });

    const clearProvinces = removeDuplicatesAndNulls(
      //@ts-ignore
      regionsFiltredBySelectedShopMainCurrency!
    );

    setRegionData(clearProvinces);

    const uniqueProvinces = [
      ...new Set(clearProvinces.map((item) => JSON.stringify(item.province))),
    ].map((province) => JSON.parse(province));

    setProvinces(
      uniqueProvinces.filter((value) => value !== undefined && value !== null)
    );
    setSelectedMunicipality(null);
  }, [regions]);

  useEffect(() => {
    const clearMunucipalities = regionData
      .filter((item) => item.province?.name === selectedProvince?.name)
      .filter((value) => value !== undefined && value !== null)
      .map((item) => item.municipality)
      .filter((value) => value?.name !== undefined && value?.name !== null);

    const uniqueMunicipalities = [
      ...new Set(clearMunucipalities.map((item) => JSON.stringify(item))),
    ].map((municipality) => JSON.parse(municipality));

    setMunicipalities(uniqueMunicipalities);
    // setSelectedMunicipality('')
    setSelectedCity(null);
  }, [selectedProvince, regionData]);

  useEffect(() => {
    const filteredCities = regionData
      .filter(
        (item) =>
          item.province?.name === selectedProvince?.name &&
          item.municipality?.name === selectedMunicipality?.name
      )
      .map((item) => item.name);

    const uniqueCities = filteredCities.filter(
      (value, index) => filteredCities.indexOf(value) === index
    );

    setCities(uniqueCities);
  }, [selectedProvince, selectedMunicipality, regionData]);

  useEffect(() => {
    const selectedData = regionData.find(
      (item) =>
        item.province?.name === selectedProvince?.name &&
        item.municipality?.name === selectedMunicipality?.name
      // && item.name === selectedCity
    );
    setSelectedPrice(selectedData ? selectedData.price : null);
  }, [selectedProvince, selectedMunicipality, selectedCity, regionData]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const handlePaymentOptionSelect = (option: SetStateAction<string>) => {
    setSelectedPaymentOption(option);
  };

  // const discount = { code: 'CHEAPSKATE', amount: '$24.00' }
  const shipping = {
    prices: selectedPrice?.amount,
    codeCurrency: selectedPrice?.codeCurrency,
  };

  const total =
    couponDetails === null
      ? calculateTotal(subTotal, shipping)
      : calculateTotalPlusDiscount(subTotal, shipping, {
          prices:
            couponDetails[0]?.codeCurrency === online_shop_main_currency
              ? couponDetails[0]?.amount
              : availableCurrencies.find((elem) => elem.isMain)?.code ===
                couponDetails[0]?.codeCurrency
              ? couponDetails[0]?.amount! /
                availableCurrencies?.find(
                  (elem) => elem.code === online_shop_main_currency
                )?.exchangeRate!
              : (couponDetails[0]?.amount! /
                  availableCurrencies?.find(
                    (elem) => elem.code === couponDetails[0]?.codeCurrency
                  )?.exchangeRate!) *
                availableCurrencies?.find(
                  (elem) => elem.code === online_shop_main_currency
                )?.exchangeRate!,
          codeCurrency: online_shop_main_currency,
        });
  const onSubmit: SubmitHandler<Record<string, string | number | boolean>> = (
    values
  ) => {
    console.log({ values });
    if (when_shop_create_preorder) {
      const {
        customerNotes,

        billing_firstName,
        billing_lastName,
        billing_street_1,
        billing_street_2,
        billing_city,
        billing_phone,
        billing_email,
      } = values;

      if (selectedMunicipality === null || selectedProvince === null) {
        if (selectedMunicipality === null && selectedProvince === null) {
          toast.error("Seleccione provincia y municipio.");
        } else if (!!selectedProvince && selectedMunicipality === null) {
          toast.error("Seleccione municipio.");
        }
      } else {
        const data = {
          products: products.map((prod) => ({
            productId: prod.id,
            quantity: prod.quantity,
            ...(prod.variationId !== null && { variationId: prod.variationId }),
          })),

          pickUpInStore: pickUpInStore,
          customerNote: customerNotes ? customerNotes : "",

          //Optional coupons
          //"coupons": ["PEPE"],

          //Optional shippingPrice
          shippingPrice: pickUpInStore
            ? null
            : {
                amount: parseFloat(shipping?.prices?.toFixed(2)!),
                codeCurrency: shipping.codeCurrency,
              },
          billing: {
            street_1: billing_street_1 ?? "",
            street_2: billing_street_2 ?? "",
            firstName: billing_firstName ?? "",
            lastName: billing_lastName ?? "",
            city: billing_city ?? "",
            postalCode: "",
            phone: JSON.stringify(billing_phone ?? ""),
            email: billing_email ?? "",
            description: "",
            municipalityId: selectedMunicipality?.id,
            provinceId: selectedProvince?.id,
            countryId: 54,
          },
        };

        createNewPreOrder(data, location.pathname, navigate);
      }
    } else {
      if (selectedPaymentOption !== "") {
        const {
          deliveryAt,

          shiping_firstName,
          shiping_lastName,
          shiping_street_1,
          shiping_street_2,
          shiping_phone,
          shiping_email,

          customerNotes,

          billing_firstName,
          billing_lastName,
          billing_street_1,
          billing_street_2,
          billing_provinceId,
          billing_countryId,
          billing_municipalityId,
          billing_city,
          billing_phone,
          billing_email,
        } = values;

        const data = {
          paymentWayCode:
            selectedPaymentOption === "opcion1"
              ? paymentWay.find((way) => way?.code === "G_COD")?.code
              : selectedPaymentOption === "opcion2"
              ? paymentWay.find((way) => way?.code === "G_CHEQUE")?.code
              : selectedPaymentOption === "opcion3" &&
                paymentWay.find((way) => way?.code === "G_TROPIPAY")?.code,
          products: products.map((prod) => ({
            productId: prod.id,
            quantity: prod.quantity,
            ...(prod.variationId !== null && { variationId: prod.variationId }),
          })),
          paymentGatewayId:
            selectedPaymentOption === "opcion1"
              ? paymentWay.find((way) => way?.code === "G_COD")?.id
              : selectedPaymentOption === "opcion2"
              ? paymentWay.find((way) => way?.code === "G_CHEQUE")?.id
              : selectedPaymentOption === "opcion3" &&
                paymentWay.find((way) => way?.code === "G_TROPIPAY")?.id,

          pickUpInStore: pickUpInStore,
          customerNote: customerNotes ? customerNotes : "",

          //Optional coupons
          coupons: couponDetails !== null ? [couponDetailsName] : [],

          //Optional shippingPrice
          shippingPrice: pickUpInStore
            ? null
            : {
                amount: parseFloat(shipping?.prices?.toFixed(2)!),
                codeCurrency: shipping.codeCurrency,
              },

          shipping: {
            street_1: pickUpInStore ? null : shiping_street_1,
            street_2: pickUpInStore
              ? null
              : shiping_street_2
              ? shiping_street_2
              : "",
            firstName: pickUpInStore ? null : shiping_firstName,
            lastName: pickUpInStore ? null : shiping_lastName ?? "",
            postalCode: pickUpInStore ? null : "",
            phone: pickUpInStore ? null : JSON.stringify(shiping_phone),
            email: pickUpInStore ? null : shiping_email,
            description: pickUpInStore ? null : "",
            city: pickUpInStore ? null : selectedCity,
            municipalityId: pickUpInStore ? null : selectedMunicipality?.id,
            provinceId: pickUpInStore ? null : selectedProvince?.id,
            countryId: pickUpInStore ? null : 54,
          },
          billing: {
            street_1: pickUpInStore
              ? billing_street_1
              : billingSameAsShiping
              ? shiping_street_1
              : billing_street_1,
            street_2: pickUpInStore
              ? billing_street_2
              : billingSameAsShiping
              ? shiping_street_2
              : billing_street_2,
            firstName: pickUpInStore
              ? billing_firstName
              : billingSameAsShiping
              ? shiping_firstName
              : billing_firstName,
            lastName: pickUpInStore
              ? billing_lastName
              : billingSameAsShiping
              ? shiping_lastName ?? ""
              : billing_lastName,
            city: pickUpInStore
              ? billing_city
              : billingSameAsShiping
              ? selectedCity
              : billing_city,
            postalCode: "",
            phone: JSON.stringify(
              pickUpInStore
                ? billing_phone
                : billingSameAsShiping
                ? shiping_phone
                : billing_phone
            ),
            email: pickUpInStore
              ? billing_email
              : billingSameAsShiping
              ? shiping_email
              : billing_email,
            description: "",
            municipalityId: pickUpInStore
              ? billing_municipalityId
              : billingSameAsShiping
              ? selectedMunicipality?.id
              : billing_municipalityId,
            provinceId: pickUpInStore
              ? billing_provinceId
              : billingSameAsShiping
              ? selectedProvince?.id
              : billing_provinceId,
            countryId: pickUpInStore
              ? billing_countryId
              : billingSameAsShiping
              ? 54
              : billing_countryId,
          },
          deliveryAt: deliveryAt,
        };

        createNewOrder(data, location.pathname, navigate);
      } else {
        setErrorString("Seleccione un método de pago");

        setTimeout(() => {
          setErrorString(null);
        }, 3000);
      }
    }
  };

  return (
    <div className="relative">
      <main className="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden transition-all">
        <div className="px-4 py-6 sm:px-6 lg:hidden">
          <div className="mx-auto flex max-w-lg">
            <a href="#">
              <span className="sr-only">Your Company</span>
              <img
                src="https://tailwindui.com/img/logos/mark.svg?color=orange&shade=600"
                alt=""
                className="h-8 w-auto"
              />
            </a>
          </div>
        </div>

        <h1 className="sr-only">Checkout</h1>

        {/* Order summary */}
        <section
          aria-labelledby="summary-heading"
          className="hidden w-full max-w-md flex-col bg-gray-50 lg:flex"
        >
          <h2 id="summary-heading" className="sr-only">
            Order summary
          </h2>

          <ul
            role="list"
            className="flex-auto divide-y divide-gray-200 overflow-y-auto px-6 pt-20"
          >
            {products.map((product) => (
              <li key={product.id} className="flex space-x-6 py-6">
                <img
                  src={product.images ? product.images.src : imageDefault}
                  alt={product.images ? product.images.src : imageDefault}
                  className="h-40 w-40 flex-none rounded-md bg-gray-200 object-cover object-center"
                />
                <div className="flex flex-col justify-between space-y-4">
                  <div className="space-y-1 text-sm font-medium">
                    <h3 className="text-gray-900">{product.name}</h3>
                    <p className="text-gray-900">
                      {printPriceWithCommasAndPeriods(
                        product.price.price * product.quantity
                      )}{" "}
                      {product.price.codeCurrency}
                    </p>
                    <p className="text-gray-500">{product.quantity} U</p>
                    {/* <p className="text-gray-500">{product.size}</p> */}
                  </div>
                  <div className="flex space-x-4">
                    <button
                      onClick={() => setOpen(true)}
                      type="button"
                      className="text-sm font-medium text-orange-600 hover:text-orange-500"
                    >
                      Editar
                    </button>
                    <div className="flex border-l border-gray-300 pl-4">
                      <button
                        onClick={() => deletedProductFromCart(product)}
                        type="button"
                        className="text-sm font-medium text-orange-600 hover:text-orange-500"
                      >
                        Remover
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div className="sticky bottom-0 flex-none border-t border-gray-200 bg-gray-50 p-6">
            {/* <form>
              <label htmlFor="discount-code" className="block text-sm font-medium text-gray-700">
                Discount code
              </label>
              <div className="mt-1 flex space-x-4">
                <input
                  type="text"
                  id="discount-code"
                  name="discount-code"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                />
                <button
                  type="submit"
                  className="rounded-md bg-gray-200 px-4 text-sm font-medium text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                  Apply
                </button>
              </div> 
            </form>  */}

            <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
              <div className="flex justify-between">
                <dt>Subtotal</dt>
                <dd className="text-gray-900">
                  {subTotal.map((st, key) => (
                    <p className="" key={key}>
                      {printPriceWithCommasAndPeriods(st.prices)}{" "}
                      {st.codeCurrency}
                    </p>
                  ))}
                </dd>
              </div>
              {/* <div className="flex justify-between">
                <dt className="flex">
                  Discount
                  <span className="ml-2 rounded-full bg-gray-200 px-2 py-0.5 text-xs tracking-wide text-gray-600">
                    {discount.code}
                  </span>
                </dt>
                <dd className="text-gray-900">-{discount.amount}</dd>
              </div> */}
              {/* <div className="flex justify-between">
                <dt>Impuestos</dt>
                <dd className="text-gray-900">{printPriceWithCommasAndPeriods(taxes.prices)} {taxes.codeCurrency}</dd>
              </div> */}
              {!pickUpInStore && !when_shop_create_preorder && (
                <div className="flex justify-between">
                  <dt>Envío</dt>
                  <dd className="text-gray-900">
                    {shipping.prices !== undefined && shipping?.prices > 0
                      ? printPriceWithCommasAndPeriods(shipping.prices) +
                        " " +
                        shipping.codeCurrency
                      : "Introduzca la dirección de envío"}
                  </dd>
                </div>
              )}

              <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                <dt className="text-base">Total</dt>
                <dd className="text-base">
                  {total.map((st, key) => {
                    if (st.prices !== undefined) {
                      return (
                        <p className="" key={key}>
                          {printPriceWithCommasAndPeriods(st.prices)}{" "}
                          {st.codeCurrency}
                        </p>
                      );
                    }
                  })}
                </dd>
              </div>
            </dl>
          </div>
        </section>

        {/* Checkout form */}
        <section
          aria-labelledby="payment-heading"
          className="flex-auto overflow-y-auto px-4 pb-16 pt-12 sm:px-6 sm:pt-16 lg:px-8 lg:pb-24 lg:pt-0 mt-0 lg:mt-28"
        >
          <div className="mx-auto max-w-lg">
            <div className="flex">
              <img
                className="mr-4 h-10 w-auto"
                src="/logo512.png"
                alt="Your Company"
              />
              <h1 className="text-2xl font-bold tracking-tight text-blue-950 sm:text-3xl">
                Finaliza tu compra
              </h1>
            </div>

            <form
              method="POST"
              className={`${pickUpInStore ? "" : "space-y-6"}`}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-1 sm:grid-cols-6">
                {enable_pick_up_in_store && enable_delivery && (
                  <div className="mt-6 flex space-x-2 col-span-full mb-10">
                    <div className="flex h-5 w-full items-center justify-start">
                      <input
                        id="same-as-shipping"
                        name="same-as-shipping"
                        type="checkbox"
                        defaultChecked={pickUpInStore}
                        checked={pickUpInStore}
                        onClick={() => setPickUpInStore(!pickUpInStore)}
                        className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                        disabled={
                          minimun_amount_to_buy_with_delivery.find(
                            (element) =>
                              element.codeCurrency === currentCurrency
                          )?.amount! >= subTotal[0]?.prices
                        }
                      />
                      <label
                        htmlFor="same-as-shipping"
                        className="cursor-pointer whitespace-nowrap ml-4 text-md font-medium text-gray-900"
                      >
                        Recoger en la tienda
                      </label>
                    </div>
                  </div>
                )}

                {!pickUpInStore &&
                  enable_delivery &&
                  minimun_amount_to_buy_with_delivery.find(
                    (element) => element.codeCurrency === currentCurrency
                  )?.amount! >= subTotal[0]?.prices && (
                    <p className="text-white bg-red-500 p-2 rounded-md opacity-80 col-span-6 mb-8">
                      Su orden no es mayor que{" "}
                      {printPriceWithCommasAndPeriods(
                        minimun_amount_to_buy_with_delivery.find(
                          (element) => element.codeCurrency === currentCurrency
                        )?.amount
                      ) +
                        " " +
                        minimun_amount_to_buy_with_delivery.find(
                          (element) => element.codeCurrency === currentCurrency
                        )?.codeCurrency}{" "}
                      para poder solicitar mensajería en la compra.
                    </p>
                  )}

                {!pickUpInStore && enable_delivery && (
                  <>
                    <h1 className="col-span-full text-xl font-semibold tracking-tight text-orange-600 sm:text-xl lg:mt-0">
                      Datos de la dirección de envío
                    </h1>

                    <div className="sm:col-span-3">
                      <Input
                        name="shiping_firstName"
                        control={control}
                        label="Nombre *"
                        rules={{ required: "Campo requerido" }}
                        defaultValue={user?.displayName}
                      />
                    </div>

                    <div className="sm:col-span-3">
                      <Input
                        name="shiping_lastName"
                        control={control}
                        label="Apellido"
                        defaultValue={user?.lastName}
                      />
                    </div>

                    <div className="col-span-full">
                      <Input
                        name="shiping_street_1"
                        control={control}
                        label="Dirección completa *"
                        rules={{ required: "Campo requerido" }}
                      />
                    </div>

                    <div className="col-span-full">
                      <Input
                        name="shiping_street_2"
                        control={control}
                        label=""
                        placeholder="Apartamento, habitación, etc. (opcional)"
                      />
                    </div>

                    <div className="sm:col-span-3">
                      <Input
                        name="shiping_countryId"
                        control={control}
                        label="País"
                        placeholder="Cuba"
                        disabled={true}
                      />
                    </div>

                    <div className="sm:col-span-3">
                      <Select
                        onChange={(e: { name: string }) => {
                          setSelectedProvince(
                            provinces.filter(
                              (province) => province.name === e.name
                            )[0]
                          );
                          setSelectedMunicipality(null);
                          setSelectedCity(null);
                        }}
                        rules={{ required: "Campo requerido" }}
                        data={provinces}
                        label="Provincia *"
                        control={control}
                        name="provinces"
                        defaultValue={undefined}
                      />
                    </div>

                    <div className="sm:col-span-3">
                      <Select
                        value={{
                          id: selectedMunicipality?.id,
                          name: selectedMunicipality?.name,
                        }}
                        onChange={(e: { name: string }) => {
                          setSelectedMunicipality(
                            municipalities.filter(
                              (munipality) => munipality.name === e.name
                            )[0]
                          );
                          setSelectedCity(null);
                        }}
                        rules={{ required: "Campo requerido" }}
                        data={municipalities}
                        label="Municipio *"
                        control={control}
                        name="municipies"
                        defaultValue={undefined}
                      />
                    </div>

                    <div className="sm:col-span-3">
                      <Select
                        value={{
                          id: cities.length === 1 ? cities[0] : selectedCity,
                          name: cities.length === 1 ? cities[0] : selectedCity,
                        }}
                        onChange={(e: { name: string }) =>
                          setSelectedCity(e.name)
                        }
                        data={cities.map((city, index) => ({
                          name: city,
                          id: index,
                        }))}
                        label="Localidad"
                        control={control}
                        name="cities"
                        defaultValue={undefined}
                      />
                    </div>

                    <div className="col-span-full">
                      <Input
                        name="shiping_phone"
                        control={control}
                        label="Número de teléfono *"
                        rules={{ required: "Campo requerido" }}
                        type="number"
                      />
                    </div>

                    <div className="col-span-full">
                      <Input
                        name="shiping_email"
                        control={control}
                        label="Correo electrónico *"
                        rules={{ required: "Campo requerido" }}
                        defaultValue={user?.email}
                      />
                    </div>

                    <div className="mt-6 flex space-x-2">
                      <div className="flex h-5 items-center">
                        <input
                          id="same-as-shipping"
                          name="same-as-shipping"
                          type="checkbox"
                          defaultChecked={billingSameAsShiping}
                          onClick={() =>
                            setBillingSameAsShiping(!billingSameAsShiping)
                          }
                          className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                        />
                      </div>
                      <label
                        htmlFor="same-as-shipping"
                        className="md:whitespace-nowrap text-sm font-medium text-gray-900"
                      >
                        Los datos de facturación son los mismos que los de envío
                      </label>
                    </div>
                  </>
                )}
              </div>

              {(!billingSameAsShiping || pickUpInStore || !enable_delivery) && (
                <div
                  className={`${
                    !pickUpInStore ? "mt-10" : ""
                  } grid grid-cols-1 gap-x-6 gap-y-1 sm:grid-cols-6`}
                >
                  <h1 className="sm:col-span-4 text-xl font-semibold tracking-tight text-orange-600 sm:text-xl lg:mt-0">
                    Introduzca sus datos de facturación
                  </h1>

                  <div className="sm:col-span-3">
                    <Input
                      name="billing_firstName"
                      control={control}
                      label="Nombre *"
                      rules={{ required: "Campo requerido" }}
                      defaultValue={user?.displayName}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <Input
                      name="billing_lastName"
                      control={control}
                      label="Apellido *"
                      rules={{ required: "Campo requerido" }}
                    />
                  </div>

                  <div className="col-span-full">
                    <Input
                      name="billing_street_1"
                      control={control}
                      label="Dirección completa *"
                      rules={{ required: "Campo requerido" }}
                    />
                  </div>
                  <div className="col-span-full">
                    <Input
                      name="billing_street_2"
                      control={control}
                      label=""
                      placeholder="Apartamento, habitación, etc. (opcional)"
                    />
                  </div>

                  <div className="sm:col-span-3">
                    {/* <AsyncComboBox
                      name="billing_countryId"
                      label="País *"
                      control={control}
                      dataQuery={{ url: "/public/countries" }}
                      normalizeData={{ id: "id", name: "name" }}
                      rules={{ required: "Campo requerido" }}
                    /> */}
                    <Input
                      name="billing_countryId"
                      control={control}
                      label="País"
                      placeholder="Cuba"
                      disabled={true}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    {/* <AsyncComboBox
                      name="billing_provinceId"
                      label="Provincia *"
                      control={control}
                      dataQuery={{
                        url: "/public/provinces",
                        defaultParams: {
                          countryId: watch("billing_countryId"),
                        },
                      }}
                      normalizeData={{ id: "id", name: "name" }}
                      dependendValue={{
                        countryId:
                          watch("billing.billing_countryId") ??
                          getValues("billing_countryId"),
                      }}
                      rules={{ required: "Campo requerido" }}
                    /> */}
                    <Select
                      onChange={(e: { name: string }) => {
                        setSelectedProvince(
                          redux_provinces?.filter(
                            (province) => province.name === e.name
                          )[0]
                        );
                        setSelectedMunicipality(null);
                        setSelectedCity(null);
                      }}
                      rules={{ required: "Campo requerido" }}
                      data={redux_provinces! ?? []}
                      label="Provincia *"
                      control={control}
                      name="provinces"
                      defaultValue={undefined}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    {/* <AsyncComboBox
                      name="billing_municipalityId"
                      label="Municipio *"
                      control={control}
                      dataQuery={{
                        url: "/public/municipalities",
                        defaultParams: {
                          countryId: watch("billing_provinceId"),
                        },
                      }}
                      normalizeData={{ id: "id", name: "name" }}
                      dependendValue={{
                        provinceId:
                          watch("billing.billing_provinceId") ??
                          getValues("billing_provinceId"),
                      }}
                      rules={{ required: "Campo requerido" }}
                    /> */}
                    <Select
                      value={{
                        id: selectedMunicipality?.id,
                        name: selectedMunicipality?.name,
                      }}
                      onChange={(e: { name: string }) => {
                        setSelectedMunicipality(
                          redux_municipalities?.filter(
                            (munipality) => munipality.name === e.name
                          )[0]
                        );
                        setSelectedCity(null);
                      }}
                      rules={{ required: "Campo requerido" }}
                      data={
                        redux_municipalities?.filter(
                          (municipality) =>
                            municipality.province?.id === selectedProvince?.id
                        ) ?? []
                      }
                      label="Municipio *"
                      control={control}
                      name="municipies"
                      defaultValue={undefined}
                      disabled={
                        selectedProvince === undefined ||
                        selectedProvince === null
                      }
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <Input
                      name="billing_city"
                      control={control}
                      label="Ciudad"
                    />
                  </div>

                  <div className="col-span-full">
                    <Input
                      name="billing_phone"
                      control={control}
                      label="Número de teléfono *"
                      rules={{ required: "Campo requerido" }}
                      type="number"
                    />
                  </div>

                  <div className="col-span-full">
                    <Input
                      name="billing_email"
                      control={control}
                      label="Correo electrónico *"
                      rules={{ required: "Campo requerido" }}
                      defaultValue={user?.email}
                    />
                  </div>
                </div>
              )}

              {/* Mobile order summary  */}
              <section
                aria-labelledby="order-heading"
                className="bg-gray-50 px-4 py-6 sm:px-6 lg:hidden"
              >
                <Disclosure as="div" className="mx-auto max-w-lg">
                  {({ open }) => (
                    <>
                      <div className="flex items-center justify-between">
                        <h2
                          id="order-heading"
                          className="text-lg font-medium text-gray-900"
                        >
                          Tu orden
                        </h2>
                        <Disclosure.Button className="font-medium text-orange-600 hover:text-orange-500">
                          {open ? (
                            <span>Ocultar resumen completo</span>
                          ) : (
                            <span>Mostrar resumen completo</span>
                          )}
                        </Disclosure.Button>
                      </div>

                      <Disclosure.Panel>
                        <ul
                          role="list"
                          className="divide-y divide-gray-200 border-b border-gray-200"
                        >
                          {products.map((product) => (
                            <li
                              key={product.id}
                              className="flex space-x-6 py-6"
                            >
                              <img
                                src={
                                  product.images
                                    ? product.images.src
                                    : imageDefault
                                }
                                alt={
                                  product.images
                                    ? product.images.src
                                    : imageDefault
                                }
                                className="h-40 w-40 flex-none rounded-md bg-gray-200 object-cover object-center"
                              />
                              <div className="flex flex-col justify-between space-y-4">
                                <div className="space-y-1 text-sm font-medium">
                                  <h3 className="text-gray-900">
                                    {product.name}
                                  </h3>
                                  <p className="text-gray-900">
                                    {product.price.price * product.quantity}{" "}
                                    {product.price.codeCurrency}
                                  </p>
                                  <p className="text-gray-500">
                                    {product.quantity} U
                                  </p>
                                  {/* <p className="text-gray-500">{product.size}</p> */}
                                </div>
                                <div className="flex space-x-4">
                                  <button
                                    onClick={() => setOpen(true)}
                                    type="button"
                                    className="text-sm font-medium text-orange-600 hover:text-orange-500"
                                  >
                                    Editar
                                  </button>
                                  <div className="flex border-l border-gray-300 pl-4">
                                    <button
                                      onClick={() =>
                                        deletedProductFromCart(product)
                                      }
                                      type="button"
                                      className="text-sm font-medium text-orange-600 hover:text-orange-500"
                                    >
                                      Remover
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>

                        {/* <form className="mt-10">
                                        <label htmlFor="discount-code-mobile" className="block text-sm font-medium text-gray-700">
                                            Discount code
                                        </label>
                                        <div className="mt-1 flex space-x-4">
                                            <input
                                                type="text"
                                                id="discount-code-mobile"
                                                name="discount-code-mobile"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                                            />
                                            <button
                                                type="submit"
                                                className="rounded-md bg-gray-200 px-4 text-sm font-medium text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </form> */}

                        <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
                          <div className="flex justify-between">
                            <dt>Subtotal</dt>
                            <dd className="text-gray-900">
                              {subTotal.map((st, key) => (
                                <p className="" key={key}>
                                  {printPriceWithCommasAndPeriods(st.prices)}{" "}
                                  {st.codeCurrency}
                                </p>
                              ))}
                            </dd>
                          </div>

                          {couponDetails !== null && (
                            <div className="flex justify-between">
                              <dt className="flex">Descuento</dt>
                              <dd className="text-gray-900">
                                {couponDetails.map((coupon) => (
                                  <p>
                                    {coupon.codeCurrency ===
                                    online_shop_main_currency
                                      ? printPrice(coupon.amount)
                                      : availableCurrencies.find(
                                          (elem) => elem.isMain
                                        )?.code === coupon.codeCurrency
                                      ? printPrice(
                                          coupon.amount! /
                                            availableCurrencies?.find(
                                              (elem) =>
                                                elem.code ===
                                                online_shop_main_currency
                                            )?.exchangeRate!
                                        )
                                      : printPrice(
                                          (coupon.amount! /
                                            availableCurrencies?.find(
                                              (elem) =>
                                                elem.code ===
                                                coupon.codeCurrency
                                            )?.exchangeRate!) *
                                            availableCurrencies?.find(
                                              (elem) =>
                                                elem.code ===
                                                online_shop_main_currency
                                            )?.exchangeRate!
                                        )}{" "}
                                    {" " + online_shop_main_currency}
                                  </p>
                                ))}
                              </dd>
                            </div>
                          )}

                          {!pickUpInStore && (
                            <div className="flex justify-between">
                              <dt>Envío</dt>
                              <dd className="text-gray-900">
                                {printPriceWithCommasAndPeriods(
                                  shipping.prices
                                )}{" "}
                                {shipping.codeCurrency}
                              </dd>
                            </div>
                          )}
                        </dl>
                      </Disclosure.Panel>

                      <p className="mt-6 flex items-center justify-between border-t border-gray-200 pt-6 text-sm font-medium text-gray-900">
                        <span className="text-base">Total</span>
                        <span className="text-base">
                          {total.map((st, key) => {
                            if (st.prices !== undefined) {
                              return (
                                <p className="" key={key}>
                                  {printPriceWithCommasAndPeriods(st.prices)}{" "}
                                  {st.codeCurrency}
                                </p>
                              );
                            }
                          })}
                        </span>
                      </p>
                    </>
                  )}
                </Disclosure>
              </section>

              <div className="col-span-full">
                <TextArea
                  name="customerNotes"
                  control={control}
                  label="Notas del pedido (opcional)"
                />
              </div>

              {!when_shop_create_preorder && (
                <div className="col-span-full">
                  {pickUpInStore ? (
                    <DateInput
                      name={"deliveryAt"}
                      label={"Fecha y hora de recogida en tienda *"}
                      control={control}
                      rules={{ required: "Este campo es requerido" }}
                      includeTime
                      // fromToday
                      fromTomorrow
                    />
                  ) : (
                    <DateInput
                      name={"deliveryAt"}
                      label={"Fecha de entrega *"}
                      control={control}
                      rules={{ required: "Este campo es requerido" }}
                      // includeTime
                      // fromToday
                      fromTomorrow
                    />
                  )}
                </div>
              )}

              {!when_shop_create_preorder && (
                <>
                  {/* Adicionar codigo de descuento */}
                  <div className="flex items-center">
                    <p
                      className={`${
                        location.pathname === "/mercadito"
                          ? "text-tienda-de-regalos-azul"
                          : location.pathname === "/" ||
                            location.pathname === "/tienda"
                          ? "text-principal-verde "
                          : location.pathname === "/estudio" &&
                            "text-estudio-rosado-viejo"
                      } font-semibold text-xl mr-4`}
                    >
                      Adicionar código de descuento
                    </p>
                    <GenericToggle
                      title=""
                      currentState={addDiscountCode}
                      changeState={setaddDiscountCode}
                    />
                  </div>

                  {addDiscountCode && (
                    <form
                      className="space-y-6 flex items-center justify-between gap-2"
                      method="POST"
                    >
                      <div className="w-full">
                        <Input
                          name="code"
                          control={control}
                          rules={{ required: "Campo requerido" }}
                          label="Código"
                          disabled={couponDetails !== null}
                        />
                      </div>

                      {couponDetails === null ? (
                        <div>
                          <Button
                            loading={isFetching}
                            name={"Verificar"}
                            color={`orange-500`}
                            action={() => {
                              VerifyCouponCode(watch("code"));
                            }}
                            disabled={couponDetails !== null}
                          />
                        </div>
                      ) : (
                        <div>
                          <Button
                            loading={isFetching}
                            name={"Eliminar código"}
                            color={"orange-500"}
                            action={() => {
                              dispatch(setCouponDetails(null));
                            }}
                          />
                        </div>
                      )}
                    </form>
                  )}

                  <div className="flex justify-between items-center w-9/12">
                    <h1 className="text-xl font-semibold tracking-tight text-orange-600 sm:text-xl mt-6 lg:mt-0">
                      Forma de pago
                    </h1>

                    {errorString !== null && (
                      <p className="mt-6 flex justify-center text-sm font-medium text-red-500">{`* ${errorString}`}</p>
                    )}
                  </div>

                  <div>
                    {paymentWay.length === 0 && <LoadingSpin color="black" />}

                    {paymentWay.some((way) => way?.code === "G_COD") && (
                      <div
                        className="my-6"
                        onClick={() => handlePaymentOptionSelect("opcion1")}
                      >
                        <input
                          type="checkbox"
                          value="opcion1"
                          checked={selectedPaymentOption === "opcion1"}
                          className="h-4 w-4 mr-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                        />
                        <label className="cursor-pointer hover:font-semibold">
                          Efectivo a la entrega
                        </label>
                      </div>
                    )}

                    {paymentWay.some((way) => way?.code === "G_CHEQUE") && (
                      <div
                        className="my-6"
                        onClick={() => handlePaymentOptionSelect("opcion2")}
                      >
                        <input
                          type="checkbox"
                          value="opcion2"
                          checked={selectedPaymentOption === "opcion2"}
                          className="h-4 w-4 mr-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                        />
                        <label className="cursor-pointer hover:font-semibold">
                          Transfermovil / Enzona
                        </label>
                      </div>
                    )}

                    {paymentWay.some((way) => way?.code === "G_TROPIPAY") && (
                      <div
                        className="my-6 flex items-center"
                        onClick={() => {
                          if (
                            !total.some((item) => item.codeCurrency === "CUP")
                          ) {
                            handlePaymentOptionSelect("opcion3");
                          }
                        }}
                      >
                        <input
                          type="checkbox"
                          value="opcion3"
                          checked={selectedPaymentOption === "opcion3"}
                          className="h-4 w-4 mr-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                          disabled={total.some(
                            (item) => item.codeCurrency === "CUP"
                          )}
                        />
                        <label
                          className={`${
                            !total.some(
                              (item) => item.codeCurrency === "CUP"
                            ) && "hover:cursor-pointer"
                          } hover:font-semibold flex`}
                        >
                          <img
                            src={tropiPayLogo}
                            alt="tropiPayLogo"
                            className="h-8 w-auto"
                          />
                        </label>

                        {total.some((item) => item.codeCurrency === "CUP") && (
                          <p className="text-red-500 ml-4 text-center">
                            (*Pagos en CUP no disponibles)
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              <div className="my-8 flex space-x-2 justify-start items-start border-t-2 pt-2">
                <div className="flex h-5 items-center">
                  <input
                    id="termsAndConditionsAccepted"
                    name="termsAndConditionsAccepted"
                    type="checkbox"
                    defaultChecked={termsAndConditionsAccepted}
                    onClick={() =>
                      setTermsAndConditionsAccepted(!termsAndConditionsAccepted)
                    }
                    className="h-4 w-4 mt-3 rounded border-gray-300 text-orange-600 focus:ring-orange-500 cursor-pointer"
                  />
                </div>
                <label
                  htmlFor="termsAndConditionsAccepted"
                  className="text-sm font-medium text-gray-700 cursor-pointer"
                >
                  He comprobado que los datos son correctos, estoy de acuerdo
                  con los costes y he leído y acepto los términos y condiciones
                  de uso del servicio.
                </label>
              </div>

              <button
                type="submit"
                disabled={loading}
                className={`
                ${
                  loading || !termsAndConditionsAccepted
                    ? "pointer-events-none bg-slate-300 focus:ring-red-300"
                    : `${
                        selectedPaymentOption === "opcion1"
                          ? "bg-green-600 hover:bg-green-700"
                          : selectedPaymentOption === "opcion2"
                          ? "bg-blue-600 hover:bg-blue-700"
                          : selectedPaymentOption === "opcion3"
                          ? "bg-yellow-300 hover:bg-yellow-400"
                          : "bg-orange-600 hover:bg-orange-700"
                      }`
                }
                mt-6 w-full rounded-md border border-transparent  px-4 py-2 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2
                
                `}
              >
                {!when_shop_create_preorder ? (
                  <div className="flex justify-center items-center w-full">
                    <p className="mr-4">Pagar</p>

                    {selectedPaymentOption === "opcion1" ? (
                      <FontAwesomeIcon
                        icon={faMoneyBill}
                        className="ml-1 text-white"
                        size="lg"
                        aria-hidden="true"
                      />
                    ) : selectedPaymentOption === "opcion2" ? (
                      <FontAwesomeIcon
                        icon={faCreditCard}
                        className="ml-1 text-white"
                        size="lg"
                        aria-hidden="true"
                      />
                    ) : (
                      selectedPaymentOption === "opcion3" && (
                        <img
                          src={tropiPayLogo}
                          alt="tropiPayLogo"
                          className="h-6 w-auto"
                        />
                      )
                    )}
                  </div>
                ) : (
                  <div className="flex justify-center items-center w-full">
                    <p className="mr-4">Reservar</p>
                  </div>
                )}

                {/* {total} */}
              </button>

              <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                <LockClosedIcon
                  className="mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                ¡Gracias por elegirnos y disfruta de tu compra con total
                seguridad!
              </p>
            </form>
          </div>
        </section>
      </main>

      {loading && (
        <div className="h-screen w-screen fixed bg-opacity-60 bg-white z-50  top-0 flex flex-col items-center justify-center">
          <SpinnerLoading text="Cargando" />
        </div>
      )}

      {tropiPayLoading && (
        <Modal
          state={tropiPayLoading}
          close={() => {
            setTropiPayLoading(false);
            setActualView("shop");
          }}
        >
          <div className="flex items-center flex-col">
            <img src={tropiPayLogo} alt="tropiPayLogo" className="h-8 w-auto" />

            <LoadingSpin color="black" />

            <p className="text-center mt-4">
              Redireccionando a TropiPay para ejecutar el pago
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
}
