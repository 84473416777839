/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
// import es from "date-fns/locale/es";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useController, UseControllerProps } from "react-hook-form";
import moment from "moment";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { subDays } from "date-fns/subDays";
import { addDays } from "date-fns";


interface DateInput {
  label: string;
  untilToday?: boolean;
  fromToday?: boolean;
  fromTomorrow?: boolean;
  fromCustom?: string;
  includeTime?: boolean
  minutesInterval?: number
  disabled?: boolean
}

const DateInput = (props: UseControllerProps & DateInput) => {
  const { field, fieldState } = useController(props);
  const { label, fromToday, fromTomorrow, fromCustom, untilToday, includeTime, defaultValue, disabled, minutesInterval } = props;

  const initialValue = field.value
    ? moment(field.value).toDate()
    : defaultValue;

  const [currentDate, setcurrentDate] = useState<Date | null>(initialValue);
  useEffect(() => {
    setcurrentDate(initialValue);
  }, [field.value]);

  const filterPassedTime = (time: string | number | Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    // Establecer la hora límite de las 8 PM
    let limiteHoraPM = 17;

    // Verificar si es sábado o domingo
    const esFinDeSemana = currentDate.getDay() === 6 || currentDate.getDay() === 0; // 6 para sábado, 0 para domingo

    // Si es fin de semana, ajustar el límite a las 5 PM en lugar de las 8 PM
    if (esFinDeSemana) {
      limiteHoraPM = 14;
    }
    // Establecer la hora límite de las 8 AM
    const limiteHoraAM = 10;

    // Obtener la hora actual y verificar si está entre 8 AM y 8 PM
    const horaActual = currentDate.getHours();
    const horaSeleccionada = selectedDate.getHours();

    // Verificar si la hora actual está entre las 8 AM y las 8 PM
    const dentroRangoHorario = horaActual >= limiteHoraAM && horaActual < limiteHoraPM;

    // Devolver verdadero si el momento actual y el momento seleccionado están en el rango horario definido
    return dentroRangoHorario && horaSeleccionada >= limiteHoraAM && horaSeleccionada < limiteHoraPM;
  };

  return (
    <div className="w-full ">
      <label
        htmlFor="label"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="relative">
        <div className="relative w-full my-5">
          <DatePicker
            onChange={(e: any) => {
              setcurrentDate(e);
              field.onChange(moment(e).format(`YYYY-MM-DD${includeTime ? " HH:mm" : ""}`));
            }}
            className={`${fieldState.error
              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500"
              : "focus:ring-gray-500 border-gray-500 focus:border-gray-600 text-gray-500"

              } ${disabled ? "bg-slate-100" : ""} w-full rounded-md sm:text-sm placeholder:text-slate-400 cursor-pointer`}
            selected={currentDate}
            placeholderText="DD-MM-YYYY"
            // locale={es}
            timeIntervals={minutesInterval ?? 30}
            timeCaption="Hora"
            timeFormat="hh:mm a"
            dateFormat={`dd MMMM yyyy ${includeTime ? "- hh:mm a" : ""}`}
            minDate={
              fromTomorrow
                ? addDays(new Date(), 1) // Si es desde mañana, establecer como mínimo el día siguiente
                : fromCustom
                  ? subDays(moment(fromCustom).toDate(), 0)
                  : fromToday
                    ? subDays(new Date(), 0)
                    : undefined
            }
            filterTime={filterPassedTime}
            // minTime={
            //   fromToday && includeTime && currentDate && moment(currentDate).isSame(new Date(), 'day')
            //     ? now.hours(now.hour()).minutes(now.minutes())
            //     : undefined
            // }
            // // minTime={now.hours(now.hour()).minutes(now.minutes())}
            // maxTime={now.hours(23).minutes(45)}
            maxDate={untilToday ? subDays(new Date(), 0) : undefined}
            popperPlacement="bottom"
            showTimeSelect={!!includeTime}
            disabled={!!disabled}
          />
          <div className="absolute p-2 gap-1 right-0 top-0 flex items-center rounded-r-md px-2 focus:outline-none">
            {fieldState.error && (
              <>
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </>
            )}
          </div>

          {fieldState.error && (
            <span className="text-xs text-red-600">
              {fieldState.error.message}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default DateInput;
